/*
 * @Author: your name
 * @Date: 2021-09-22 23:19:38
 * @LastEditTime: 2022-01-14 09:45:33
 * @LastEditors: wuqinfa
 * @Description: In User Settings Edit
 * @FilePath: /mxc-crm-3.0/src/services/template.ts
 */
import request from '@/utils/request';

// https://sit.miaocode.com/core/userIntegrationRecord/integrationPageForKK?pageNum=1&pageSize=2&userId=83988
export async function getIntegrationPage(params: any) {
  return request('/userIntegrationRecord/integrationPageForKK', {
    method: 'GET',
    data: params,
    prefix: API_URL_V2,
  });
}

/**
 * 获取用户总积分
 */
export async function getTotalIntegration(params: any) {
  return request('/crm-user/userIntegration/getTotalIntegration', {
    method: 'GET',
    data: params,
  });
}
