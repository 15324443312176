/*
 * @Author: liusuyun
 * @Date: 2021-10-25 15:44:19
 * @LastEditors: liusuyun
 * @LastEditTime: 2022-02-22 18:53:37
 * @Description:学员排课管理
 */
import request from '@/utils/request';

/**
 * CC工作台-我的学员-排课详情
 */
export async function getStudentScheduleInfo(params: any) {
  return request('/crm-teaching/arrangeCourse/getStudentScheduleInfo', {
    method: 'GET',
    data: params
  });
}

/**
 * CC工作台-我的学员-更新学员常规班排课
 */
export async function updateStudentFreeTime(params: any) {
  return request('/crm-teaching/arrangeCourse/updateStudentFreeTime', {
    method: 'POST',
    data: params
  });
}

/**
 * CC工作台-我的学员-获取已开班的课程列表
 */
export async function chooseOpenClassByPage(params: any) {
  return request('/crm-teaching/arrangeCourse/listOpenClassRule', {
    method: 'POST',
    data: params
  });
}

/**
 * CC工作台-我的学员-获取即将开班的课程列表
 */
export async function listLessonNotStartCourse(params: any) {
  return request('/crm-teaching/course/listLessonNotStartCourse', {
    method: 'POST',
    data: params,
    requestType: 'form'
  });
}

/**
 * CC工作台-我的学员-即将开课约课
 */
export async function joinCourse(params: any) {
  return request('/student_auto__schedule/api/joinCourse', {
    data: params,
    prefix: API_URL_V2
    // requestType: 'form',
  });
}

/**
 * CC工作台-我的学员-取消排课
 */
export async function cancelStudentSchedule(params: any) {
  return request('/crm-teaching/arrangeCourse/cancelStudentSchedule', {
    method: 'GET',
    data: params
  });
}

/**
 * 学员-获取开班时段表格
 */
export async function listOpenClassRuleTable(params: any) {
  return request('/crm-teaching/arrangeCourse/listOpenClassRuleTable', {
    method: 'POST',
    data: params
  });
}

/**
 * 学员-获取快捷续课的信息
 */
export async function getFastClass(params: any) {
  return request('/crm-teaching/arrangeCourse/getFastClass', {
    data: params
  });
}

/**
 * 学员-更新排课备注
 */
export async function updateStudentRemark(params: any) {
  return request('/crm-teaching/arrangeCourse/updateStudentRemark', {
    data: params
  });
}
