/*
 * @Author: wuqinfa
 * @Date: 2021-09-22 23:19:38
 * @LastEditTime: 2022-01-18 10:54:27
 * @LastEditors: wuqinfa
 * @Description: 外呼
 */
import request from '@/utils/request';

/**
 * 外呼呼出，请求 websock 使用的 callId
 */
export async function callOut(params: any) {
  return request('/crm-market/emi/callOut', {
    method: 'POST',
    data: params,
    requestType: 'form',
  });
}

/**
 * 取消外呼/挂断
 */
export async function callCancel(params: any) {
  return request('/crm-market/emi/callCancel', {
    method: 'POST',
    data: params,
    requestType: 'form',
  });
}

/**
 * 毛leads外呼（Surface），请求 websock 使用的 callId
 */
export async function leadsCoarseCallOut(params: any) {
  return request('/crm-market/leadsCoarse/callOut', {
    method: 'POST',
    data: params,
    requestType: 'form',
  });
}

export const emiMessageSocketUrl = `${WEBSOCK_URL}/gzwebsocket/emiMessage/`;
