/*
 * @Author: your name
 * @Date: 2021-09-23 18:18:48
 * @LastEditTime: 2022-01-14 09:45:24
 * @LastEditors: wuqinfa
 * @Description: In User Settings Edit
 */
import {
  getIntegrationPage,
  getTotalIntegration,
} from '../services/marketIntegration';

export default {
  namespace: 'marketIntegration',

  state: {
    detail: {}, // 数据详情

    totalIntegration: 0,
  },

  effects: {
    * getIntegrationPage({ payload, extra }: any, { call, put }: any): any {
      const response = yield call(getIntegrationPage, payload);

      const {
        list = [],
        pageNum = 1, // V2 的分页
        pageSize = 10, // V2 的分页
        total = 0,
      } = response.data || {};

      // ProTable 的 request 属性，需要返回特定数据结构的 Promise 对象
      return {
        data: list,
        total,
        success: true,
        page: pageNum,
        pageSize: pageSize,
      };
    },

    * getTotalIntegration ({ payload }: any, { call, put }: any): any {
      const response = yield call(getTotalIntegration, payload);

      yield put({
        type: '_getTotalIntegration',
        payload: response
      });

      return response;
    },
  },

  reducers: {
    _getTotalIntegration(state: any, { payload }: any) {
      return {
        ...state,
        totalIntegration: payload.data || 0,
      };
    },
  },
};
