/*
 * @Author: wuqinfa
 * @Date: 2022-01-19 09:22:59
 * @LastEditTime: 2022-01-25 18:16:26
 * @LastEditors: zhanghj
 * @Description:
 */
const electron = window.electron;
export const ipcRenderer = electron ? electron.ipcRenderer : false;
const MAX_WINDOW = 'MAX_WINDOW';
const UN_MAX_WINDOW = 'UN_MAX_WINDOW';
const MIN_WINDOW = 'MIN_WINDOW';
const CLOSE_WINDOW = 'CLOSE_WINDOW';
const GET_DRIVE_USAGE = 'GET_DRIVE_USAGE';
const RECEIVE_DEVICE_USAGE = 'RECEIVE_DEVICE_USAGE';
const UPLOAD_RECORD = 'UPLOAD_RECORD';
const DELETE_RECORD = 'DELETE_RECORD';
const CHECK_FOR_UPDATES = 'CHECK_FOR_UPDATES';
const UPDATE_AVAILABLE_RESULT = 'UPDATE_AVAILABLE_RESULT';
const START_UPDATE = 'START_UPDATE';
const DOWN_LOAD_PROGRESS = 'DOWN_LOAD_PROGRESS';
const STOP_UPDATE = 'STOP_UPDATE';
const HOT_UPDATE_SUCCESS = 'HOT_UPDATE_SUCCESS';
const RELAUNCH_APP = 'RELAUNCH_APP';
const OPEN_EXTERNAL = 'OPEN_EXTERNAL';

export const ON_WINDOW_MAX = 'ON_WINDOW_MAX';
export const ON_WINDOW_UN_MAX = 'ON_WINDOW_UN_MAX';
export const ON_WINDOW_RESTORE = 'ON_WINDOW_RESTORE';

export function maxWindow() {
    ipcRenderer && ipcRenderer.send(MAX_WINDOW);
}
export function unMaxWindow() {
    ipcRenderer && ipcRenderer.send(UN_MAX_WINDOW);
}
export function minWindow() {
    ipcRenderer && ipcRenderer.send(MIN_WINDOW);
}
export function closeWindow() {
    ipcRenderer && ipcRenderer.send(CLOSE_WINDOW);
}
/** 获取硬盘的使用情况 */
export function getDriveUsage() {
    ipcRenderer && ipcRenderer.send(GET_DRIVE_USAGE);
}
/** 获取硬盘的使用情况 */
export function receiveDriveUsage(callback: (e: any, data: number) => void) {
    ipcRenderer && ipcRenderer.on(RECEIVE_DEVICE_USAGE, callback);
}
export function removeReceiveDriveUsage(callback: (e: any, data: number) => void) {
    ipcRenderer && ipcRenderer.removeListener(RECEIVE_DEVICE_USAGE, callback);
}

export function uploadRecord(name: string) {
    ipcRenderer && ipcRenderer.send(UPLOAD_RECORD, name);
}

export function deleteRecord(name: string) {
    ipcRenderer && ipcRenderer.send(DELETE_RECORD, name);
}


/** 检查是否有更新 */
export function checkForUpdate() {
    ipcRenderer && ipcRenderer.send(CHECK_FOR_UPDATES, true)
}

// 监听是否由可用更新
export function updateAvailableResult(callback: any) {
    ipcRenderer && ipcRenderer.on(UPDATE_AVAILABLE_RESULT, callback)
}

// 移除监听是否由可用更新
export function removeUpdateAvailableResult(callback: any) {
    ipcRenderer && ipcRenderer.removeListener(UPDATE_AVAILABLE_RESULT, callback)
}


// 开始更新
export function startUpdate() {
    ipcRenderer && ipcRenderer.send(START_UPDATE)
}

// 下载进度
export function downloadProgress(callback: any) {
    ipcRenderer && ipcRenderer.on(DOWN_LOAD_PROGRESS, callback)
}

// 停止更新
export function stopUpdate() {
    ipcRenderer && ipcRenderer.send(STOP_UPDATE, true)
}

export function hotUpdateSuccess(callback: any) {
    ipcRenderer && ipcRenderer.on(HOT_UPDATE_SUCCESS, callback)
}

// 重启app
export function relaunchApp() {
    ipcRenderer && ipcRenderer.send(RELAUNCH_APP)
}

// 打开浏览器的窗口 url 新窗口链接
export function openExternal(url: string) {
    ipcRenderer && ipcRenderer.send(OPEN_EXTERNAL, url)
}

// 窗口恢复
export function windowRestore(callback: (e: any, data: number) => void) {
  ipcRenderer && ipcRenderer.on(ON_WINDOW_RESTORE, callback);
}
