/*
 * @Author: your name
 * @Date: 2022-01-05 11:22:09
 * @LastEditTime: 2022-03-09 14:45:31
 * @LastEditors: liusuyun
 * @Description: 工具类函数，无法分类的都统一放在这个文件下
 */
import lodash from 'lodash';
import { history, getDvaApp } from 'umi';
import request from 'umi-request';
import { message } from 'antd';

import * as cache from '@/utils/cache';
import provincesCities from './provinces_and_cities';

/**
 * 随机获取头像
 * @param {*} type
 * @returns
 */
export function randomHead(type: string = '') {
  const random = Math.floor(Math.random() * 5) + 1;
  if (type === 'boy') {
    return require(`../assets/images/heads/boy${random}.png`);
  } else if (type === 'girl') {
    return require(`../assets/images/heads/girl${random}.png`);
  } else {
    return require(`../assets/images/heads/${random}.png`);
  }
}

/**
 * 从全局字典 dictionary 中获取 dictName 或 itemName
 * @param {*} dictCode
 * @param {*} nameType
 * @param {*} itemValue
 * @returns
 */
export const getDictionaryName = (
  dictCode: string,
  nameType: string,
  itemValue: string | number,
) => {
  const dictionary = cache.get('dictionary');
  let result = '';

  if (!dictionary) {
    return;
  }

  const { dictItems = [], dictName = '' } = dictionary[dictCode] || {};

  switch (nameType) {
    case 'dictName':
      result = dictName;
      break;
    case 'itemName':
      const temp = lodash.find(dictItems, (item) => {
        return item.itemValue == itemValue; // 不能用全等，后端返回数据有 number 和 string 两种类型
      });

      if (temp) {
        result = temp.itemName || '';
      }

      break;
    default:
      break;
  }

  return result;
};

/**
 * 获取目标的数据类型
 * @param {*} target
 * @returns
 * const mapTag = '[object Map]';
 * const setTag = '[object Set]';
 * const arrayTag = '[object Array]';
 * const objectTag = '[object Object]';
 * const boolTag = '[object Boolean]';
 * const dateTag = '[object Date]';
 * const errorTag = '[object Error]';
 * const numberTag = '[object Number]';
 * const regexpTag = '[object RegExp]';
 * const stringTag = '[object String]';
 * const symbolTag = '[object Symbol]';
 * 等。。。
 * 详细参考：https://p1-jj.byteimg.com/tos-cn-i-t2oaga2asx/gold-user-assets/2019/9/1/16ce893dc0828b6a~tplv-t2oaga2asx-watermark.awebp
 */
export const getType = (target: any) => {
  return Object.prototype.toString.call(target);
};

/*
  格式化省市数据
*/
export const formatProvincesCities = () => {
  const arr = [];
  for (const i in provincesCities) {
    const children = provincesCities[i].map((v: string) => {
      return {
        value: v,
        label: v,
      };
    });

    arr.push({
      value: i,
      label: i,
      children,
    });
  }
  return arr;
};

/**
 * 密码加密(ps：非可靠的加密方式)
 * @param str
 * @returns
 */
export const encodePwd = (str: string) => {
  let out = '';
  // eslint-disable-next-line no-restricted-syntax
  for (const c of str) {
    out += c.charCodeAt(0) - 23;
  }
  return out;
};

export const logout = () => {
  getDvaApp()._store.dispatch({ type: 'login/logout' });
  localStorage.removeItem('mxc-token');
  sessionStorage.clear();
  history.replace({
    pathname: '/login',
  });
};

/*
  复制文本
*/
export const copyText = (str: string) => {
  const oInput = document.createElement('input');
  oInput.value = str;
  document.body.appendChild(oInput);
  oInput.select(); // 选择对象
  document.execCommand('Copy'); // 执行浏览器复制命令
  oInput.className = 'oInput';
  oInput.style.display = 'none';
  message.success('复制成功');
};

/*
  获取url参数
*/
export const getQueryValue = (queryName: string) => {
  const query = decodeURI(window.location.href).split('?')[1];
  if (!query) return null;
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (pair[0] == queryName) return pair[1];
  }
  return null;
};

/*
  生成短链 复制到剪贴板
*/
export const shorten = (longUrl = '') => {
  // return request('https://short-url.miaocode.com/api/url/shorten', {
  //   method: 'POST',
  //   // url: 'https://short-url.miaocode.com/api/url/shorten',
  //   // data: longUrl,
  //   params:{longUrl:longUrl},
  //   // prefix:
  // });
  request
    .post('https://short-url.miaocode.com/api/url/shorten', {
      data: { longUrl: longUrl },
    })
    .then(function (response) {
      copyText(response?.shortUrl);
    })
    ['catch'](function (error) {
      // 接口没有返回状态码，当做错误状态处理了
      copyText(error?.shortUrl);
    });
};
