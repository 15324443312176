import request from '@/utils/request';

/**
 * 获取表单基本信息
 */
export async function getBaseInfo(params: any) {
  return request('/crm-market/leads/walk/getBaseInfo', {
    method: 'GET',
    data: params
  });
}
/**
 * 获取试听课报告信息
 */
export async function getDemoUserLessonReport(params: any) {
  return request('/crm-teaching/leadsBooking/getDemoLessonAbility', {
    method: 'GET',
    data: params,
  });
}

/**
 * 保存leads信息
 */
export async function save(params: any) {
  return request('/crm-market/leads/walk/save', {
    method: 'POST',
    data: params
  });
}

/**
 * 保存问卷信息
 */
export async function saveQuestions(params: any) {
  return request('/crm-market/leads/questionnaire/save', {
    method: 'POST',
    data: params
  });
}

/**
 * 获取调查问卷信息
 */
export async function getWalkInfo(params: any) {
  return request('/crm-market/leads/walk/getWalkInfo', {
    method: 'GET',
    data: params
  });
}

/**
 * 统计用户有无信息接口
 */
export async function getCount(params: any) {
  return request('/crm-market/leads/walk/getCount', {
    method: 'GET',
    data: params
  });
}

/**
 * 判断leads归属
 */
export async function checkLeads(params: any) {
  return request('/crm-market/leads/walk/checkLeads', {
    method: 'GET',
    data: params
  });
}

/**
 * 检查手机号是否存在
 */
export async function checkPhone(params: any) {
  return request('/crm-market/leads/walk/checkPhone', {
    method: 'GET',
    data: params,
    showError: true
  });
}

/**
 * 获取试听课报告
 */
export async function getDemoPreviewUrl(params: any) {
  return request('/crm-teaching/leadsBooking/getDemoPreviewUrl', {
    method: 'GET',
    data: params,
    showError: true
  });
}

/**
 * 根据用户id获取定级测评信息
 */
export async function getByUserId(params: any) {
  return request('/userRatingAssessment/getByUserId', {
    method: 'GET',
    data: params,
    showError: true,
    prefix: API_URL_V2
  });
}

/**
 * 获取等级评测结果
 */
export async function getTestResult(params: any) {
  return request('/crm-teaching/userRatingAssessment/getTestResult', {
    method: 'GET',
    data: params,
    showError: true
  });
}

/**
 * 获取等级评测记录
 */
 export async function getTestResultRecord(params: any) {
  return request('/crm-teaching/userRatingAssessment/getTestResultRecord', {
    method: 'GET',
    data: params,
    showError: true
  });
}
