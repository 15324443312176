/*
 * @Author: your name
 * @Date: 2021-09-22 23:19:16
 * @LastEditTime: 2022-02-24 15:49:25
 * @LastEditors: liusuyun
 * @Description: leads 详情页 - 约课
 */
import lodash from 'lodash';

import {
  booking,
  getBookingRecord,
  cancelBooking,
  changeBooking,
  getBookingPool,
  getUserCurrentBooking,
  leadsCoarseBooking,
  hadSevereAttend
} from '../services/ccLeadsBooking';

export default {
  namespace: 'ccLeadsBooking',

  state: {
    userCurrentBooking: {}, // 获取个人当前约课信息（已约了哪些课程）
    isHadSevereAttend: false, // 是否重度出席当前课程类型试听课
    leadsActionRef: ''
  },

  effects: {
    * booking({ payload }: any, { call }: any): any {
      const response = yield call(booking, payload);

      return response;
    },

    * getBookingRecord({ payload }: any, { call }: any): any {
      const response = yield call(getBookingRecord, payload);

      const { data = [] } = response || {};

      // ProTable 的 request 属性，需要返回特定数据结构的 Promise 对象
      return {
        data: data,
        success: true
      };
    },

    * cancelBooking({ payload }: any, { call }: any): any {
      const response = yield call(cancelBooking, payload);

      return response;
    },

    * changeBooking({ payload }: any, { call }: any): any {
      const response = yield call(changeBooking, payload);

      return response;
    },

    * getBookingPool({ payload }: any, { call }: any): any {
      const response = yield call(getBookingPool, payload);

      const { data = [] } = response || {};
      // const temp = lodash.cloneDeep(data).map((item) => {
      //   const {
      //     id,
      //     time,
      //     monday,
      //     tuesday,
      //     wednesday,
      //     thursday,
      //     friday,
      //     saturday,
      //     sunday,
      //   } = item;

      //   // 过滤掉没有排课的时间段
      //   if (
      //     !monday?.length
      //     && !tuesday?.length
      //     && !wednesday?.length
      //     && !thursday?.length
      //     && !friday?.length
      //     && !saturday?.length
      //     && !sunday?.length
      //   ) {
      //     return null;
      //   }

      //   return item;

      //   // return {
      //   //   id,
      //   //   time,
      //   //   mon: monday,
      //   //   tues: tuesday,
      //   //   wed: wednesday,
      //   //   thur: thursday,
      //   //   fri: friday,
      //   //   sat: saturday,
      //   //   sun: sunday,
      //   // }
      // });

      // ProTable 的 request 属性，需要返回特定数据结构的 Promise 对象
      return {
        data: data,
        success: true
      };
    },

    * getUserCurrentBooking({ payload }: any, { call, put }: any): any {
      const response = yield call(getUserCurrentBooking, payload);

      yield put({
        type: '_getUserCurrentBooking',
        payload: response
      });

      return response;
    },

    * leadsCoarseBooking({ payload }: any, { call }: any): any {
      const response = yield call(leadsCoarseBooking, payload);

      return response;
    },

    * hadSevereAttend({ payload }: any, { call, put }: any): any {
      const response = yield call(hadSevereAttend, payload);

      yield put({
        type: '_hadSevereAttend',
        payload: response
      });

      return response;
    },

    * updateLeadsActionRef({ payload }: any, { put }: any): any {
      yield put({
        type: '_updateLeadsActionRef',
        payload: payload
      });
    }
  },

  reducers: {
    _getUserCurrentBooking(state: any, { payload }: any) {
      return {
        ...state,
        userCurrentBooking: payload.data || {}
      };
    },

    _hadSevereAttend(state: any, { payload }: any) {
      return {
        ...state,
        isHadSevereAttend: payload.data || false
      };
    },

    _updateLeadsActionRef(state: any, { payload }: any) {
      return {
        ...state,
        leadsActionRef: payload
      };
    }
  }
};
