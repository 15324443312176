/*
 * @Author: your name
 * @Date: 2021-09-22 23:19:38
 * @LastEditTime: 2022-01-08 11:26:35
 * @LastEditors: linlianghao
 * @Description: 素材页 + 课程包页的视频图片缓存，由于此组件本来只设计用于素材页的视频缓存，
 *               后来产品提出素材页图片以及课程包的图片也需要缓存，但是课程包页的数据结构与素材页的不一样，
 *               因此将课程包页的数据结构转成与素材页一样，其中campusChannelId为0的时候则为略缩图，1-n分别代表第n张详情图；
 *               materialContent则为课程包中的课程系列名字；
 */
import { getAllVideo, getGoodsCache } from '@/services/material';
import { getDriveUsage, receiveDriveUsage, removeReceiveDriveUsage } from '@/utils/ipcRenderer';
import log from '@/utils/log';

export interface MaterialCache {
  id: string,
  campusChannelId: string,
  materialName: string,
  materialContent: string,
  updateTime: string | undefined
}

interface Identification {
  identification: string, // 唯一标识
  updateTime: string | undefined
}

// 素材类型
const MATERIAL_TYPE = {
  IMAGE: 0, // 图片
  VIDEO: 1, // 视频
  H5: 2 // 图片 + H5
}

// 资源存放路径
const MATERIAL_ROOT_PATH = "C:\\MxcDownload";
// 一定时间不操作进行静默下载
const TIME_FLAG = 3 * 60 * 1000;
// 禁止下载的页面
const CAN_NOT_DOWNLOAD_PATH = [
  "/customer/material", // 素材页
  "/customer/course-package" // 课程包页
]
// 硬盘占用超过一定范围就不做缓存了
const DRIVE_USAGE_LIMIT = 0.9;

class MaterialService {
  static instance: MaterialService | null = null;
  // 素材视频列表
  videoList: MaterialCache[] = [];
  // 素材图片列表
  imageList: MaterialCache[] = [];
  // 课程包图片列表
  coursePackageImageList: MaterialCache[] = [];
  // 设备硬盘使用情况（surface上只有C盘）
  driveUsage: number = 0;
  // 防抖定时器
  timeInterval: NodeJS.Timeout = setTimeout(()=>{}, 0);
  // 写入流
  writeStream: any = null;
  // 是否终止下载
  isStop: boolean = true;

  static getInstance() {
    if (!this.instance) {
      this.instance = new MaterialService();
    }
    return this.instance;
  }

  init = async() => {
    try {
      log.info('MaterialService', `init MaterialService`);
      // 获取视频素材列表
      this.getAllMaterialVideo();
      // 获取图片素材列表
      this.getAllMaterialImage();
      // 获取课程包图片素材列表
      this.getAllCoursePackageImage();

      // 判断是否存在需要使到用的工具（通过Electron传入）
      if (!window.path || !window.fs || !window.request) {
        log.error("[MaterialService]", "Missing Tools Path、fs、request");
        return;
      }
      
      // 获取磁盘剩余的空间
      receiveDriveUsage(this.setDriveUsage)
      getDriveUsage();

      // 创建根目录
      const hasRootPath = await this.checkRootPath();
      if (!hasRootPath) return;

      // 监听document的点击事件
      document.addEventListener("click", this.handleClick);

      // 先主动触发一次
      this.handleClick();
    } catch (err) {
      console.error("[MaterialService] init Error", err)
    }
  }

  uninit = () => {
    log.info("MaterialService", "uninit MaterialService")
    document.removeEventListener("click", this.handleClick);
    removeReceiveDriveUsage(this.setDriveUsage)
    this.stopDownload();
  }

  /** 获取素材页本地缓存 */
  getLocalCache = ({item, type = MATERIAL_TYPE.VIDEO}: {item: MaterialCache, type: number}) => {
    const { materialContent} = item;
    const filePath = this.getFilePath({item, type});
    let result: string = materialContent;

    return new Promise((resolve) => {
			window.fs.stat(filePath, (err: any, res: any) => {
        // console.log("[MaterialService] Get Local Cache", res, err);
        
        // 若不报错则找到该文件
				if (!err) result = filePath;

				resolve(result);
			})
		})
  }

  /** 获取课程包页本地缓存 */
  getCoursePackageLocalCache = async(item: any) => {
    try {
      const { id, courseLayer, headThumbUrl, detailPics, updateTime } = item;
      const detailPicsList = detailPics.split(";");
      let result = {
        headThumbUrl: headThumbUrl,
        detailPics: detailPics,
      }

      const headTumbPromise = new Promise((resolve) => {
        // 略缩图
        let headThumbResult = headThumbUrl;
        const headThumbItem: MaterialCache  = {
          id,
          campusChannelId: "0",
          materialName: courseLayer,
          materialContent: headThumbUrl,
          updateTime
        }
        const filePath = this.getFilePath({item: headThumbItem, type: MATERIAL_TYPE.IMAGE})

        window.fs.stat(filePath, (err: any, res: any) => {        
          // 若不报错则找到该文件
          if (!err) headThumbResult = filePath;

          resolve(headThumbResult);
        })
      })

      const detailPicsPromise = detailPicsList.map((detailPicUrl: string, index: number) => {
        return new Promise((resolve) => {
          // 详情图
          let detailPicResult = detailPicUrl;
          const detailPicItem: MaterialCache  = {
            id,
            campusChannelId: (index + 1).toString(),
            materialName: courseLayer,
            materialContent: detailPicUrl,
            updateTime
          }
          const filePath = this.getFilePath({item: detailPicItem, type: MATERIAL_TYPE.IMAGE})
    
          window.fs.stat(filePath, (err: any, res: any) => {        
            // 若不报错则找到该文件
            if (!err) detailPicResult = filePath;
    
            resolve(detailPicResult);
          })
        })
      })

      const headTumbResult = await headTumbPromise;
      const detailPicsResultList = await Promise.all(detailPicsPromise);
      result = {
        headThumbUrl: headTumbResult,
        detailPics: detailPicsResultList.join(";"),
      }

      return result;
    } catch (err) {
      console.error("[MaterialService] Get CoursePackage Local Cache Error", err);
    }
  }  

  /** 获取视频素材列表 */
  getAllMaterialVideo = async() => {
    try {
      if (this.videoList.length === 0) {
        // type 1 为获取全部视频素材
        const {data} = await getAllVideo({type: 1});
        this.videoList = data;
      }
    } catch (err) {
      console.error("[MaterialService] Get All Material Video", err)
    }
  }

  /** 获取图片素材列表 */
  getAllMaterialImage = async() => {
    try {
      if (this.imageList.length === 0) {
        // type 2 为获取全部图片素材
        const {data} = await getAllVideo({type: 2});
        this.imageList = data;
      }
    } catch (err) {
      console.error("[MaterialService] Get All Material Image", err)
    }
  }

  
  /** 获取课程包图片列表 */
  getAllCoursePackageImage = async() => {
    try {
      if (this.coursePackageImageList.length === 0) {
        const { data } = await getGoodsCache();
        // 将课程包获取到的图片列表，改造成与素材页获取到的图片列表数据结构一致
        // 一个id的课程包存在一张略缩图与多张详情图，因此规定campusChannelId为0的时候为略缩图，1-n分别代表第n张详情图
        let coursePackageImageList: MaterialCache[] = [];
        data.forEach((item: any) => {
          const { id, courseLayer, headThumbUrl, detailPics, updateTime } = item;
          // 略缩图
          const headThumbItem: MaterialCache  = {
            id,
            campusChannelId: "0",
            materialName: courseLayer,
            materialContent: headThumbUrl,
            updateTime
          }
          coursePackageImageList.push(headThumbItem);

          // 详情图
          const detailPicsList = detailPics.split(";");
          detailPicsList.forEach((detailPicUrl: string, index: number) => {
            const detailPicItem: MaterialCache = {
              id,
              campusChannelId: (index + 1).toString(),
              materialName: courseLayer,
              materialContent: detailPicUrl,
              updateTime
            }
            coursePackageImageList.push(detailPicItem);
          })
        })

        this.coursePackageImageList = coursePackageImageList;
      }
    } catch (err) {
      console.error("[MaterialService] Get All Material Image", err)
    }
  }

  /** 处理点击事件 */
  handleClick = () => {
    // 防抖，一定时间用户不操作才下载，防止影响占用带宽
    clearTimeout(this.timeInterval);
    this.timeInterval = setTimeout(this.start, TIME_FLAG);
    this.stopDownload();
  }

  /** 开始执行下载操作 */
  start = async() => {
    log.info("MaterialService", "Start Material Service");
    // 在某些页面禁止下载
    const canDownload = this.getCanDownload();
    if (!canDownload) return;

    // 先清理掉非最新文件和非已完成的文件
    this.checkDelFile();
    
    // 硬盘空间是否不足
    const isMaxDriveUsage = this.driveUsage > DRIVE_USAGE_LIMIT;
    if (isMaxDriveUsage) {
      log.error("MaterialService", `DriveUsage is Max，Can Not Download, driveUsage: ${this.driveUsage}`);
      return;
    }

    this.isStop = false;

    // 下载素材图片
    for (let i = 0; i < this.imageList.length; i ++) {
      const item = this.imageList[i];

      const hasExist = await this.checkDownload(item, MATERIAL_TYPE.IMAGE) as boolean;

      if (!hasExist && !this.isStop) {
        await this.startDownload(item, MATERIAL_TYPE.IMAGE);
      }
    }

    // 下载课程包图片
    for (let i = 0; i < this.coursePackageImageList.length; i ++) {
      const item = this.coursePackageImageList[i];

      const hasExist = await this.checkDownload(item, MATERIAL_TYPE.IMAGE) as boolean;

      if (!hasExist && !this.isStop) {
        await this.startDownload(item, MATERIAL_TYPE.IMAGE);
      }
    }
  
    // 下载课程包视频
    for (let i = 0; i < this.videoList.length; i ++) {
      const item = this.videoList[i];

      const hasExist = await this.checkDownload(item, MATERIAL_TYPE.VIDEO) as boolean;

      if (!hasExist && !this.isStop) {
        await this.startDownload(item, MATERIAL_TYPE.VIDEO);
      }
    }
  }

  /** 判断是否已下载 */
  checkDownload = (item: MaterialCache, type: number) => {
    const filePath = this.getFilePath({item, type});

    return new Promise((resolve) => {
			window.fs.stat(filePath, (err: any, res: any) => {        
        // 若不报错则找到该文件
				if (!err) resolve(true);

				resolve(false);
			})
		})
  }

  /** 开始下载 */
  startDownload = async(item: MaterialCache, type: number) => {
    const { materialContent} = item;
    // 文件后缀
    const postfix = materialContent.split(".")[materialContent.split(".").length-1];
    // 支持下载的图片后缀
    const imagePostfix = ["jpg", "jpeg", "png"];
    const isImage = (type === MATERIAL_TYPE.IMAGE)
    if (isImage && !imagePostfix.includes(postfix)) return;

    const filePath = this.getFilePath({item, isOrigin: true, type});
    log.info("MaterialService", `Start Download, filePath: ${filePath}`);

    return new Promise((resolve) => {
			this.writeStream = window.fs.createWriteStream(filePath);
			this.writeStream.on('error', (err: any) => {
					log.error('MaterialService', `Download Error ${JSON.stringify(err)}`);
					resolve(false);
			})
			this.writeStream.on('finish', () => {
          if (!this.isStop) {
            log.info('MaterialService', `Download Finish, filePath: ${filePath}`);
            this.finishDownload(filePath, type);
            resolve(true);
          }
			})
			
			window.request(materialContent).pipe(this.writeStream)
		})
  }

  /** 终止下载 */
  stopDownload = () => {
    this.isStop = true;
    if (this.writeStream) {
      log.info("MaterialService", "Stop Download");
      this.writeStream.end();
      this.writeStream = null;
    }
  }

  /** 下载完成（修改名字，打上finish的标记） */
  finishDownload = (filePath: string, type: number) => {
    const isImg = (type === MATERIAL_TYPE.IMAGE);
    const isH5 = (type === MATERIAL_TYPE.H5);
    const newFilPath = `${filePath.split(".")[0]}-finish.${(isImg || isH5) ? "jpg" : "mp4"}`;
    window.fs.rename(filePath, newFilPath, (err: any) => {
      if (err) { console.error("[MaterialService] Rename Err", err) }
    });
  }

  /** 清理非最新文件和非已完成的文件 */
  checkDelFile = () => {
    // 通过远端获取的视频素材列表，由（频道id+id）的形式创建素材唯一值
    let onLineFileList: Identification[] = [];
    const originList = [...this.videoList, ...this.imageList]
    originList.forEach((item: MaterialCache) => {
      const { id, campusChannelId, updateTime } = item;
      const newUpdateTime = updateTime ? new Date(updateTime).getTime()+"" : "null";
      const identification = `${campusChannelId}-${id}`;
      onLineFileList.push({ identification, updateTime: newUpdateTime });
    })

    // 获取素材文件夹下的所有文件，找到与远端一致的文件后，判断更新时间和是否完成
    const files = window.fs.readdirSync(MATERIAL_ROOT_PATH);
    files.forEach((file: any) => {
      const fileName = file.split(".")[0];
      const fileParameter = fileName.split("-");
      const campusChannelId = fileParameter[0];
      const id = fileParameter[1];
      const updateTime = fileParameter[3];
      const isFinish = fileParameter[4];

      const curIdentification = `${campusChannelId}-${id}`;

      const fileTemp = onLineFileList.find((item: Identification) => item.identification === curIdentification);

      if (fileTemp) {
        // 若更新时间不一致，则说明该文件更新，把旧的删除
        if (fileTemp.updateTime && updateTime !== fileTemp.updateTime) {
          this.delFile(file);
          return;
        }
        // 若没有finish的标记，则说明该未完整下载，做删除处理
        if (!isFinish) {
          this.delFile(file);
          return;
        }
      } else {
        // 若本地存在某个视频文件，在接口返回的列表中不存在，则删除这个文件
        if (onLineFileList.length > 0) {
          // 接口返回的列表长度不为0的情况下（防止偶尔接口请求失败，将全部文件删除了）
          this.delFile(file);
          return;
        }
      }
    })
  }

  /** 删除指定文件 */
  delFile = (file: string) => {
    try {
      const filePath = window.path.resolve(MATERIAL_ROOT_PATH, file);
      log.info('MaterialService', `Del File, filePath: ${filePath}`)
      window.fs.unlinkSync(filePath);
    } catch (err) {
      log.error("MaterialService", `Del File Error ${JSON.stringify(err)}`)
    }
  }

  /** 
   * 获取文件路径
   * @param  { MaterialCache } item  文件对象
   * @param  { boolean } isOrigin  是否源文件（源文件没有finish后缀）
   */
  getFilePath = ({item, isOrigin = false, type}: { item: MaterialCache, isOrigin?: boolean, type?: number}) => {
    const { id, campusChannelId, materialName, updateTime } = item;
    const newUpdateTime = updateTime ? new Date(updateTime).getTime() : "null";
    const isImg = (type === MATERIAL_TYPE.IMAGE);
    const isH5 = (type === MATERIAL_TYPE.H5);
    const fileName = `${campusChannelId}-${id}-${materialName}-${newUpdateTime}${isOrigin ? "": "-finish"}`;
    const filePath = window.path.resolve(MATERIAL_ROOT_PATH, `${fileName}.${(isImg || isH5) ? "jpg" : "mp4"}`);
    return filePath;
  }

  /** 创建根目录文件夹 */
  checkRootPath = () => {
    return new Promise((resolve) => {
      window.fs.access(MATERIAL_ROOT_PATH, (err: any)=>{
        if (err) {
          log.info('MaterialService', 'Create Root Path');
          // 没有目录，尝试创建目录
          window.fs.mkdir(MATERIAL_ROOT_PATH, (mkdirError: any) => {
            if (mkdirError) {
              log.error('MaterialService', `Create Root Path Error ${mkdirError}`);
              resolve(false);
            } else {
              log.info('MaterialService', 'Create Root Path Success');
              resolve(true);
            }
          });
        } else {
          resolve(true);
        }
      })
    })
  }

  /** 获取到设备硬盘的使用情况 */
  setDriveUsage = (e: any, driveUsage: number) => {
    log.info("MaterialService", `DriveUsage: ${driveUsage}`);
    this.driveUsage = driveUsage;
  }

  /** 判断此页面是否允许做下载动作 */
  getCanDownload = () => {
    const hash = window.location.hash;
    log.info("MaterialService",  `Current History is ${hash}`);
    let canDownload = true;
    CAN_NOT_DOWNLOAD_PATH.forEach((item: string) => {
      if (hash.includes(item)) {
        canDownload = false;
        log.info("MaterialService", "Current Path Can Not Download");
      }
    })
    return canDownload;
  }
}

export default MaterialService.getInstance();