import { ipcRenderer } from '@/utils/ipcRenderer';

// 日志记录，用法：log.info(scope, data),log.error(scope, data)
// scope: 是业务模块，如record(录音), update(更新)
// data: 日志数据，可以是string或者object

const logLevels = ['error', 'warn', 'info', 'verbose', 'debug', 'silly'];
function createFun(level: string) {
    return (scope: string, data: any) => {
        if(!ipcRenderer) return;
        ipcRenderer.send(`${level}_log`, JSON.stringify({
            scope: scope,
            data,
        }));
    }
}
const log: {[propname: string]: Function} = {};

logLevels.forEach(item => {
    log[item] = createFun(item);
})

export default log;