/*
 * @Author: your name
 * @Date: 2021-09-22 23:19:16
 * @LastEditTime: 2022-02-16 16:06:44
 * @LastEditors: liusuyun
 * @Description: 渠道管理
 */
import {
  listTreeChannel,
  getAllChannel,
  getChannelList,
  listAll
} from '../services/marketChannel';

const resetFieldName = (data: []) => {
  return data.map((item) => {
    const { label, value, children } = item;
    const result = {
      title: label,
      value
    };

    if (children && children.length) {
      result.children = resetFieldName(children);
    }

    return result;
  });
};

export default {
  namespace: 'marketChannel',

  state: {
    listTreeChannel: [] // leads 来源（渠道层级）树形数据结构
  },

  effects: {
    * listTreeChannel({ payload, extra }: any, { call, put }: any): any {
      const response = yield call(listTreeChannel, payload);

      yield put({
        type: '_listTreeChannel',
        payload: response
      });

      return response;
    },

    * getAllChannel({ payload, extra }: any, { call, put }: any): any {
      const response = yield call(getAllChannel, payload);

      return response;
    },
    * getChannelList({ payload, extra }: any, { call, put }: any): any {
      const response = yield call(getChannelList, payload);

      return response;
    },

    * listAll({ payload, extra }: any, { call, put }: any): any {
      const response = yield call(listAll, payload);

      return response.data;
    }
  },

  reducers: {
    _listTreeChannel(state: any, { payload }: any) {
      const { data = [] } = payload;

      return {
        ...state,
        listTreeChannel: resetFieldName(data)
      };
    }
  }
};
