/*
 * @Author: your name
 * @Date: 2021-12-11 23:33:07
 * @LastEditTime: 2022-01-13 09:50:35
 * @LastEditors: wuqinfa
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /painter-eva/src/app.ts
 */
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

/* dva 运行时配置 */
export const dva = {
  config: {
    onError(err: Error) {
      return Promise.reject(err);
      // e.preventDefault();
    },
  },
};
