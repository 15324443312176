/*
 * @Author: liusuyun
 * @Date: 2021-10-25 16:26:04
 * @LastEditors: liusuyun
 * @LastEditTime: 2022-02-22 18:53:18
 * @Description:学员排课管理
 */
import * as Api from '../services/arrangeCourse';

export default {
  namespace: 'arrangeCourse',

  state: {
    detail: {},

    listLessonNotStartCourse: null // 后端返回的是数组。如果值是 null，表示还没请求后台的接口
  },

  effects: {
    * getStudentScheduleInfo({ payload }: any, { call, put }: any): any {
      try {
        const response = yield call(Api.getStudentScheduleInfo, payload);
        yield put({
          type: '_getStudentScheduleInfo',
          payload: response.data
        });
        return response;
      } catch (error) {
        yield put({
          type: '_getStudentScheduleInfo',
          payload: ''
        });
      }
    },

    * cancelStudentSchedule({ payload }: any, { call, put }: any): any {
      const response = yield call(Api.cancelStudentSchedule, payload);
      return response;
    },

    * chooseOpenClassByPage({ payload }: any, { call, put }: any): any {
      const response = yield call(Api.chooseOpenClassByPage, payload);
      const { records = [], current = 1, size = 10, total = 0 } = response.data || {};
      return {
        data: records,
        total,
        success: true,
        page: current,
        pageSize: size
      };
    },

    * listLessonNotStartCourse({ payload }: any, { call, put }: any): any {
      const response = yield call(Api.listLessonNotStartCourse, payload);
      const { records = [], current = 1, size = 10, total = 0 } = response.data || {};

      yield put({
        type: '_listLessonNotStartCourse',
        payload: records
      });

      return {
        data: records,
        total,
        success: true,
        page: current,
        pageSize: size
      };
    },

    * updateStudentFreeTime({ payload }: any, { call, put }: any): any {
      const response = yield call(Api.updateStudentFreeTime, payload);
      return response;
    },

    * joinCourse({ payload }: any, { call, put }: any): any {
      const response = yield call(Api.joinCourse, payload);
      return response;
    },

    * listOpenClassRuleTable({ payload }: any, { call }: any): any {
      const response = yield call(Api.listOpenClassRuleTable, payload);

      const {
        data = []
      } = response || {};

      // ProTable 的 request 属性，需要返回特定数据结构的 Promise 对象
      return {
        data: data,
        success: true
      };
    },

    * updateStudentRemark({ payload }: any, { call, put }: any): any {
      const response = yield call(Api.updateStudentRemark, payload);
      return response;
    },

    * getFastClass({ payload }: any, { call, put }: any): any {
      const response = yield call(Api.getFastClass, payload);
      return response;
    }
  },
  reducers: {
    _getStudentScheduleInfo(state: any, { payload }: any) {
      return {
        ...state,
        detail: payload
      };
    },

    _listLessonNotStartCourse(state: any, { payload }: any) {
      return {
        ...state,
        listLessonNotStartCourse: payload
      };
    }
  }
};
