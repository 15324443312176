/*
 * @Description: 日期工具类
 * @Author: zhanghj
 * @Date: 2022-01-04 18:10:58
 * @LastEditors: zhanghj
 * @LastEditTime: 2022-01-05 14:52:37
 */
import dayjs from 'dayjs'

export function getToday(){
  const value = dayjs().format('YYYY-MM-DD')
  const dateArr = value.split('-')
  const year = dateArr[0]
  const month = dateArr[1]
  const day = dateArr[2]
  return [{
    year,
    month,
    day,
    value,
    label: '今日'
  }]
}

export function getAfterDay(num: number = 6){
  const d = dayjs()
  const arr = []
  for(let i = 1; i < num; i++){
    const value = d.add(i, 'day').format('YYYY-MM-DD')
    const dateArr = value.split('-')
    const year = dateArr[0]
    const month = dateArr[1]
    const day = dateArr[2]
    const o = {
      year,
      month,
      day,
      value,
      label: `${day}日`,
    }
    arr.push(o)
  }
  return arr
}

export function getBeforeDay(num: number = 30){
  const d = dayjs()
  const arr = []
  for(let i = 1; i < num; i++){
    const value = d.subtract(i, 'day').format('YYYY-MM-DD')
    const dateArr = value.split('-')
    const year = dateArr[0]
    const month = dateArr[1]
    const day = dateArr[2]
    const o = {
      year,
      month,
      day,
      value,
      label: `${day}日`,
    }
    arr.push(o)
  }
  return arr.reverse()
}
