/*
 * @Author: your name
 * @Date: 2021-09-22 23:19:38
 * @LastEditTime: 2021-09-26 15:13:46
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /mxc-crm-3.0/src/services/template.ts
 */
import request from '@/utils/request';

/**
 * 获取系统全部字典
 */
export async function dictionary(params: any) {
  return request('/crm-account/dictionary/all', {
    method: 'GET',
    data: params,
  });
}
