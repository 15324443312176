// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/mxc/jenkins/workspace/mxc-web-omo-sit3/renderer/omo/node_modules/umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/PageLoading/index';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BasicLayout' */'@/layouts/BasicLayout'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/business",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BusinessLayout' */'@/layouts/BusinessLayout'), loading: LoadingComponent}),
        "routes": [
          {
            "path": "/business/leads",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__business__leads' */'@/pages/business/leads'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/business/student",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__business__student' */'@/pages/business/student'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/business/activity",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__business__activity' */'@/pages/business/activity'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/business/library",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__business__library' */'@/pages/business/library'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/business/userInfo",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__business__userInfo' */'@/pages/business/userInfo'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/customer",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__customer__index' */'@/pages/customer/index'), loading: LoadingComponent}),
        "routes": [
          {
            "title": "首页",
            "path": "/customer/home",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__customer__home__index' */'@/pages/customer/home/index'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "title": "频道",
            "path": "/customer/channel",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__customer__channel__index' */'@/pages/customer/channel/index'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "title": "材料",
            "path": "/customer/material",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__customer__material__index' */'@/pages/customer/material/index'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "title": "课程包",
            "path": "/customer/course-package",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__customer__coursePackage__index' */'@/pages/customer/coursePackage/index'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "title": "订单",
            "path": "/customer/order",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__customer__order__index' */'@/pages/customer/order/index'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "title": "完成订单",
            "path": "/customer/paySuccess",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__customer__paySuccess__index' */'@/pages/customer/paySuccess/index'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "title": "转介绍福利",
            "path": "/customer/referBenefits",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__customer__referBenefits__index' */'@/pages/customer/referBenefits/index'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "title": "未预约-基本信息",
            "path": "/customer/unappointment/basic-info",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__customer__registration__pages__unappointment__basic-info' */'@/pages/customer/registration/pages/unappointment/basic-info'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "title": "未预约-孩子情况",
            "path": "/customer/unappointment/submit-info",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__customer__registration__pages__unappointment__submit-info' */'@/pages/customer/registration/pages/unappointment/submit-info'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "title": "未预约-已提交信息",
            "path": "/customer/unappointment/view-info",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__customer__registration__pages__unappointment__view-info' */'@/pages/customer/registration/pages/unappointment/view-info'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "title": "已预约-基本情况",
            "path": "/customer/appointment/basic-info",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__customer__registration__pages__appointment__basic-info' */'@/pages/customer/registration/pages/appointment/basic-info'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "title": "已预约-孩子情况",
            "path": "/customer/appointment/submit-info",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__customer__registration__pages__appointment__submit-info' */'@/pages/customer/registration/pages/appointment/submit-info'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "title": "已预约-孩子情况",
            "path": "/customer/appointment/view-info",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__customer__registration__pages__appointment__view-info' */'@/pages/customer/registration/pages/appointment/view-info'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "title": "已预约-孩子情况",
            "path": "/customer/appointment/index",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__customer__registration__pages__index' */'@/pages/customer/registration/pages/index'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "title": "等级测评报告K系列",
            "path": "/customer/userProjectReportK",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__customer__registration__pages__appointment__userProjectReportK' */'@/pages/customer/registration/pages/appointment/userProjectReportK'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "title": "等级测评报告S系列（图形化）",
            "path": "/customer/userProjectReportS",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__customer__registration__pages__appointment__userProjectReportS' */'@/pages/customer/registration/pages/appointment/userProjectReportS'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "title": "试听课报告",
            "path": "/customer/listenReport/index",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__customer__registration__pages__listenReport__index' */'@/pages/customer/registration/pages/listenReport/index'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/login",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__login__index' */'@/pages/login/index'), loading: LoadingComponent})
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
