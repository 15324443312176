/*
 * @Author: liusuyun
 * @Date: 2021-09-14 10:26:39
 * @LastEditors: wuqinfa
 * @LastEditTime: 2022-01-19 09:28:01
 * @Description:登录、登出、获取用户信息
 */
import request from '@/utils/request';

export async function login(params: any) {
  return request('/crm-account/account/login', {
    method: 'POST',
    data: params,
    // requestType: 'form',
  });
}

export async function logout() {
  return request('/crm-account/account/logout', {
    method: 'POST',
  });
}

export async function getCurrentAccount() {
  return request('/crm-account/account/getCurrentAccount', {
    method: 'GET',
  });
}

export async function changePassword(params: any) {
  return request('/crm-account/account/changePassword', {
    method: 'POST',
    data: params,
  });
}

/**
 * 获取登录详情信息
 */
export async function getDetail(params: any) {
  return request('/crm-account/account/getDetail', {
    method: 'GET',
    data: params,
  });
}
