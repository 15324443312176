/*
 * @Author: linlianghao
 * @Date: 2021-08-12 21:45:40
 * @LastEditors: linlianghao
 * @LastEditTime: 2022-01-11 11:10:09
 * @Description:
 */
import { getCoursePackageList } from "@/services/material";
import MaterialService from "@/utils/materialService";

export default {
  namespace: 'coursePackage',
  state: {
    coursePackageList: []
  },
  effects: {
    * getCoursePackageList({ payload }: any, { call, put }: any): any {
      try {
        const { canUseCache } = payload
        const { data } = yield call(getCoursePackageList);

        // 替换资源（查询是否有本地缓存）
        let newData = [...data];
        for (let i = 0; i < data.length; i ++) {
          const item = data[i];

          if ( canUseCache ) {
            const { id, courseLayer, headThumbUrl, detailPics, updateTime } = item;
            const opt = { id, courseLayer, headThumbUrl, detailPics, updateTime };
            const res = yield call(MaterialService.getCoursePackageLocalCache, opt);
            item.headThumbUrl = res.headThumbUrl;
            item.detailPics = res.detailPics;
          }
          newData[i] = item;
        }

        yield put({
          type: '_setCoursePackageList',
          payload: newData
        });
      } catch (err) {
        console.error("[CoursePackage] Get CoursePackage List Error", err);
      }
    },
  },
  reducers: {
    _setCoursePackageList (state: any, { payload }: any) {
      return {
        ...state,
        coursePackageList: payload,
      };
    }
  },
};
