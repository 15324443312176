/*
 * @Author: your name
 * @Date: 2021-09-22 23:19:16
 * @LastEditTime: 2021-09-26 16:50:45
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /mxc-crm-3.0/src/models/dictionary.ts
 */
import {
  dictionary,
} from '../services/dictionary';

import * as cache from '@/utils/cache';

export default {
  namespace: 'dictionary',

  state: {
    /*
      使用 key - value 的数据格式保存字典，比如：
      {
        'INTENTION': {
          "dictName": "意向度",
          "dictCode": "INTENTION",
          "dictItems": [{
            "itemName": "低意向",
            "itemValue": "0"
          }, {
            "itemName": "中意向",
            "itemValue": "1"
          }, {
            "itemName": "高意向",
            "itemValue": "2"
          }]
        },
      }
    */
    dictionary: cache.get('dictionary') || {},
  },

  effects: {
    * dictionary ({ payload }: any, { call, put }: any): any {
      const response = yield call(dictionary, payload);

      yield put({
        type: '_dictionary',
        payload: response
      });

      return response;
    },
  },

  reducers: {
    _dictionary(state: any, { payload }: any) {
      const {
        data = [],
      } = payload;
      const temp = {};

      data.forEach(item => {
        const {
          dictCode,
          dictItems,
        } = item;

        if (dictCode && dictItems && dictItems.length) {
          temp[dictCode] = item;
        }
      });

      cache.set('dictionary', temp);

      return {
        ...state,
        dictionary: temp,
      };
    },
  },
};
