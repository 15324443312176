/*
 * @Author: your name
 * @Date: 2021-09-22 23:19:16
 * @LastEditTime: 2022-03-02 14:56:15
 * @LastEditors: liusuyun
 * @Description: In User Settings Edit
 */
import {
  listSchoolStudent,
  schoolStudentData,
  details,
  updateStudentDetails,
  getCourseBySid,
  getEduOperation,
  addRevisitRecord,
  listWaitOpenClass,
  getAllCcList,
  listAllCcByDept,
  listByCourseProgress,
  listByClock,
  getRelegationOrderByUserId,
  relegationCancel,
  getConsumePeriodByOrderNo,
  getConsumePeriod,
  relegationApply,
  getConsumePeriodLog,
  getPayTradeByRecordId,
  getCourseLayerList,
  getCirculationList,
  offlinePayCallback,
  getGoodPriceByCourseLayer,
} from '../services/student';

export default {
  namespace: 'student',

  state: {
    detail: {}, // 学员详情

    schoolStudentData: {
      waitingCount: 0, // 待开班数量
      classingCount: 0, // 在班数量
      classOverCount: 0, // 结课数量
      refundCount: 0, // 退费数量
      suspensionCount: 0, // 休学数量
      allCount: 0, // 全部数
    },
    listSchoolStudent: [], // 学员 - 全部 列表
    consumePeriodByOrderNo: {},
    curTableRef: null,

    listWaitOpenClass: [], // 我的学员列表-待开班
    listByCourseProgress: [], // 我的学员列表-续费
    listByClock: [], // 我的学员列表-打卡

    // 我的学员列表-打卡，“本周未打卡、本周已打卡”统计
    clockStat: {
      unClockCurWeek: 0,
      clockedCurWeek: 0,
    },

    // 我的学员列表-续费，“在班阶段A、在班阶段B、在班阶段C、本月结课、已结课”统计
    renewalStat: {
      stageA: 0,
      stageB: 0,
      stageC: 0,
      finishedCurMonth: 0,
      finished: 0,
    },

    // 我的学员列表-待开班，“未提交排课、已提交排课”统计
    arrangeCourseStat: {
      noArrange: 0,
      arranged: 0,
    },

    salerList: [],
    salerListByDept: [],
    consumePeriodByOrderNo:{}
  },

  effects: {
    *updataconsumePeriodByOrderNo({ payload }: any, { call, put }: any): any {
      yield put({
        type: '_consumePeriodByOrderNo',
        payload: {data:payload},
      });
    },
    // 线下补差回调
    *offlinePayCallback({ payload }: any, { call, put }: any): any {
      const response = yield call(offlinePayCallback, payload);
      return {
        data: response.data,
        success: true,
      };
    },
    // 获取申请记录id对应的流转记录
    *getCirculationList({ payload }: any, { call, put }: any): any {
      const response = yield call(getCirculationList, payload);
      return {
        data: response.data,
        success: true,
      };
    },
    // 获取申请记录id对应的课消修改记录
    *getConsumePeriodLog({ payload }: any, { call, put }: any): any {
      const response = yield call(getConsumePeriodLog, payload);
      return {
        data: response.data,
        success: true,
      };
    },

    // 获取申请记录id对应的支付流水记录
    *getPayTradeByRecordId({ payload }: any, { call, put }: any): any {
      const response = yield call(getPayTradeByRecordId, payload);
      return {
        data: response.data,
        success: true,
      };
    },
    // 获取所有课程等级
    *getCourseLayerList({ payload }: any, { call, put }: any): any {
      const response = yield call(getCourseLayerList, payload);
      return {
        data: response.data,
        success: true,
      };
    },
    // 获取订单课消信息
    *getConsumePeriodByOrderNo({ payload }: any, { call, put }: any): any {
      const response = yield call(getConsumePeriodByOrderNo, payload);
      yield put({
        type: '_consumePeriodByOrderNo',
        payload: response,
      });
      return {
        data: [response.data],
        success: true,
      };
    },
    //  保存/提交申请
    *relegationApply({ payload }: any, { call, put }: any): any {
      const response = yield call(relegationApply, payload);
      return {
        data: response.data,
        success: true,
      };
    },
    // 升降级提交-课消提示 提交申请的时候先调这个接口，判断已消课次和已填课次是否相同
    *getConsumePeriod({ payload }: any, { call, put }: any): any {
      const response = yield call(getConsumePeriod, payload);
      return {
        data: response.data,
        success: true,
      };
    },

    //  撤销申请
    *relegationCancel({ payload }: any, { call, put }: any): any {
      const response = yield call(relegationCancel, payload);
      return {
        data: response.data,
        success: true,
      };
    },

    *getGoodPriceByCourseLayer({ payload }: any, { call, put }: any): any {
      const response = yield call(getGoodPriceByCourseLayer, payload);
      return {
        data: response.data,
        success: true,
      };
    },

    *getRelegationOrderByUserId({ payload }: any, { call, put }: any): any {
      const response = yield call(getRelegationOrderByUserId, payload);
      return {
        data: response.data,
        success: true,
      };
    },
    *listSchoolStudent({ payload }: any, { call, put }: any): any {
      // 每次重新请求列表都要调用一次统计数据接口
      yield put({
        type: 'schoolStudentData',
        payload: payload,
      });

      const response = yield call(listSchoolStudent, payload);

      const {
        records = [],
        current = 1,
        size = 10,
        total = 0,
        // pages = 0,
      } = response.data || {};

      yield put({
        type: '_listSchoolStudent',
        payload: records,
      });

      // ProTable 的 request 属性，需要返回特定数据结构的 Promise 对象
      return {
        data: records,
        total,
        success: true,
        page: current,
        pageSize: size,
      };
    },

    *schoolStudentData({ payload }: any, { call, put }: any): any {
      const response = yield call(schoolStudentData, payload);

      yield put({
        type: '_schoolStudentData',
        payload: response,
      });

      return response;
    },

    *details({ payload }: any, { call, put }: any): any {
      const response = yield call(details, payload);

      yield put({
        type: '_details',
        payload: response,
      });

      return response;
    },

    *updateStudentDetails({ payload }: any, { call, put }: any): any {
      const response = yield call(updateStudentDetails, payload);

      yield put({
        type: '_details',
        payload: response,
      });

      return response;
    },

    *getCourseBySid({ payload }: any, { call, put }: any): any {
      const response = yield call(getCourseBySid, payload);

      // ProTable 的 request 属性，需要返回特定数据结构的 Promise 对象
      return {
        data: response.data,
        success: true,
      };
    },

    *getEduOperation({ payload }: any, { call, put }: any): any {
      const response = yield call(getEduOperation, payload);

      return response;
    },

    *addRevisitRecord({ payload }: any, { call, put }: any): any {
      const response = yield call(addRevisitRecord, payload);

      return response;
    },

    *listWaitOpenClass({ payload }: any, { call, put }: any): any {
      const response = yield call(listWaitOpenClass, payload);

      yield put({
        type: '_listWaitOpenClass',
        payload: response,
      });

      const { page = {} } = response.data || {};
      const { records = [], current = 1, size = 10, total = 0 } = page;

      // ProTable 的 request 属性，需要返回特定数据结构的 Promise 对象
      return {
        data: records,
        success: true,
        total,
        page: current,
        pageSize: size,
      };
    },

    *getAllCcList({ payload, extra }: any, { call, put }: any): any {
      const response = yield call(getAllCcList, payload);
      yield put({
        type: '_getAllCcList',
        payload: response.data,
      });
      return response;
    },

    *listAllCcByDept({ payload, extra }: any, { call, put }: any): any {
      const response = yield call(listAllCcByDept, payload);
      yield put({
        type: '_listAllCcByDept',
        payload: response.data,
      });
      return response;
    },

    *listByCourseProgress({ payload }: any, { call, put }: any): any {
      const response = yield call(listByCourseProgress, payload);

      yield put({
        type: '_listByCourseProgress',
        payload: response,
      });

      const { page = {} } = response.data || {};
      const { records = [], current = 1, size = 10, total = 0 } = page;

      // ProTable 的 request 属性，需要返回特定数据结构的 Promise 对象
      return {
        data: records,
        success: true,
        total,
        page: current,
        pageSize: size,
      };
    },

    *listByClock({ payload }: any, { call, put }: any): any {
      const response = yield call(listByClock, payload);

      yield put({
        type: '_listByClock',
        payload: response,
      });

      const { page = {} } = response.data || {};
      const { records = [], current = 1, size = 10, total = 0 } = page;
      // ProTable 的 request 属性，需要返回特定数据结构的 Promise 对象
      return {
        data: records,
        success: true,
        total,
        page: current,
        pageSize: size,
      };
    },
  },

  reducers: {
    _consumePeriodByOrderNo(state: any, { payload }: any) {
      return {
        ...state,
        consumePeriodByOrderNo: payload.data || {},
      };
    },
    _listSchoolStudent(state: any, { payload }: any) {
      return {
        ...state,
        listSchoolStudent: payload,
      };
    },

    _schoolStudentData(state: any, { payload }: any) {
      return {
        ...state,
        schoolStudentData: payload.data || {},
      };
    },

    _details(state: any, { payload }: any) {
      return {
        ...state,
        detail: payload.data || {},
      };
    },

    _setCurTableRef(state: any, { payload }: any) {
      return {
        ...state,
        curTableRef: payload,
      };
    },

    _listWaitOpenClass(state: any, { payload }: any) {
      const { page = {}, stat = {} } = payload.data || {};
      const { records = [] } = page;

      return {
        ...state,
        listWaitOpenClass: records,
        arrangeCourseStat: stat,
      };
    },

    _getAllCcList(state: any, { payload }: any) {
      return {
        ...state,
        salerList: payload,
      };
    },

    _listAllCcByDept(state: any, { payload }: any) {
      return {
        ...state,
        salerListByDept: payload,
      };
    },

    _listByCourseProgress(state: any, { payload }: any) {
      const { page = {}, stat = {} } = payload.data || {};
      const { records = [] } = page;
      return {
        ...state,
        listByCourseProgress: records,
        renewalStat: stat,
      };
    },

    _listByClock(state: any, { payload }: any) {
      const { stat = {}, page = {} } = payload.data || {};
      const { records = [] } = page;
      return {
        ...state,
        listByClock: records,
        clockStat: stat,
      };
    },
  },
};
