/*
 * @Author: liusuyun
 * @Date: 2021-09-13 10:25:08
 * @LastEditors: liusuyun
 * @LastEditTime: 2022-03-24 16:29:09
 * @Description:
 */
/** Request 网络请求工具 更详细的 api 文档 : https://github.com/umijs/umi-request */
// @ts-nocheck
import { extend } from 'umi-request';
import { stringify as qsStringify } from 'qs';
// import { Toast  } from 'antd-mobile';

import { logout } from '@/utils';
import * as cache from '@/utils/cache';
import { message } from 'antd';

const printErrorInfo = (error: {}) => {
  // console.log('error', error);

  const { code, message, status, timestamp, request = {} } = error || {};
  const { url, options } = request;
  const { method, headers, params, data } = options || {};
  const paramsKeys = Object.keys(params || {});
  const tempParams = paramsKeys && paramsKeys.length ? params : data; // 接口的参数有可能在 params 中也有可能在 data 里

  const errorTagStyle = [
    'color: #ff4d4f',
    'background: #fff2f0',
    'padding: 2px 6px',
    'font-size: 12px',
    'border-radius: 2px',
    'border: 1px solid #ff4d4f',
  ].join(';');

  console.log('%c接口报错信息', errorTagStyle);
  console.log(`
    ------------- error-start -------------
    时间：${timestamp || new Date().toString()}
    HTTP Status：${status || 200}
    接口路径：${url}
    请求类型：${method}
    headers：${JSON.stringify(headers)}
    参数：${JSON.stringify(tempParams || {})}
    code：${code}
    message：${message}
    ------------- error-end ---------------
  `);
};

// const codeMessage: Record<number, string> = {
//   200: '服务器成功返回请求的数据。',
//   201: '新建或修改数据成功。',
//   202: '一个请求已经进入后台排队（异步任务）。',
//   204: '删除数据成功。',
//   400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
//   401: '用户没有权限（令牌、用户名、密码错误）。',
//   403: '用户得到授权，但是访问是被禁止的。',
//   404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
//   406: '请求的格式不可得。',
//   410: '请求的资源被永久删除，且不会再得到的。',
//   422: '当创建一个对象时，发生一个验证错误。',
//   500: '服务器发生错误，请检查服务器。',
//   502: '网关错误。',
//   503: '服务不可用，服务器暂时过载或维护。',
//   504: '网关超时。',
// };

/** 异常处理程序 */
const errorHandler = (error: { response: Response }): Response => {
  if (error) {
    printErrorInfo(error);

    if (error.code === '40100') {
      logout();
    }
    if (!error.request.options.showError) {
      message.error(error.message ? error.message : '服务异常，请联系技术支持');
    }
  } else {
    message.error('您的网络发生异常，无法连接服务器');
  }

  return Promise.reject(error);
};

/** 配置request请求时的默认参数 */
const request = extend({
  prefix: API_URL,
  timeout: 20000,
  errorHandler, // 默认错误处理
  credentials: 'include', // 默认请求是否带上cookie
});

// request拦截器, 改变url 或 options.
request.interceptors.request.use((url, options) => {
  const token = cache.get('mxc-token');
  const { headers = {} } = options || {};
  const tokenHeaders = {
    'mxc-token': token,
    ...headers,
  };

  if (options.method?.toUpperCase() === 'GET') {
    options.params = options.data;
    options.paramsSerializer = (params) => {
      return qsStringify(params, { arrayFormat: 'comma' });
    };
  } else {
    options.requestType = options.requestType ? options.requestType : 'json';
  }

  if (token) {
    // TODO: 临时加上这个，不然测试环境请求 V2 无法跨域
    if (url.includes(API_URL_V2)) {
      return {
        url,
        options: { ...options },
      };
    }

    if (MXC_HOST) {
      // 用来指定本地开发环境的机器，只有本地开发环境需要，其它环境都不需要
      tokenHeaders['mxc-host'] = MXC_HOST;
    }

    return {
      url,
      options: { ...options, headers: tokenHeaders },
    };
  }

  return {
    url,
    options: { ...options },
  };
});

request.interceptors.response.use(async (response) => {
  const data = await response.clone().json();
  if (data.success) {
    return response;
  }
  return Promise.reject(data);
});

export default request;
