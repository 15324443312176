/*
 * @Author: your name
 * @Date: 2021-09-26 15:04:33
 * @LastEditTime: 2022-01-05 11:18:39
 * @LastEditors: Please set LastEditors
 * @Description: localStorage 通用工具函数
 */

export const set = (key: string, value: any, ttl = CACHE_EXPIRED_TIME) => {
  localStorage.setItem(
    key,
    JSON.stringify({
      data: value,
      expiredTime: new Date().getTime() + ttl,
    }),
  );
};

export const get = (key: string) => {
  const getItem = localStorage.getItem(key);

  try {
    if (getItem && JSON.parse(getItem) && JSON.parse(getItem).expiredTime > new Date().getTime()) {
      return JSON.parse(getItem).data ?? ''; //null或undefined时返回''
    } else {
      // return false;
      return ''; //* 返回空字符串感觉更好用 */
    }
  } catch (e) {
    return '';
  }
};
