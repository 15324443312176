/*
 * @Author: your name
 * @Date: 2021-09-22 23:19:16
 * @LastEditTime: 2022-01-14 15:48:02
 * @LastEditors: wuqinfa
 * @Description: In User Settings Edit
 */
import {
  tableData,
  dataDetail,
  add,
  update,
  remove,
} from '../services/template';

export default {
  namespace: 'template',

  state: {
    detail: {}, // 数据详情
  },

  effects: {
    *tableData({ payload, extra }: any, { call, put }: any): any {
      const response = yield call(tableData, payload);

      const {
        records = [],
        current = 1,
        size = 10,
        total = 0,
        // pages = 0,
      } = response.data || {};

      // ProTable 的 request 属性，需要返回特定数据结构的 Promise 对象
      return {
        data: records,
        total,
        success: true,
        page: current,
        pageSize: size,
      };
    },

    *add ({ payload }: any, { call, put }: any): any {
      const response = yield call(add, payload);

      return response;
    },

    *dataDetail ({ payload }: any, { call, put }: any): any {
      const response = yield call(dataDetail, payload);

      yield put({
        type: '_fetchDetail',
        payload: response
      });

      return response;
    },

    *update ({ payload }: any, { call, put }: any): any {
      const response = yield call(update, payload);

      return response;
    },

    *remove ({ payload }: any, { call, put }: any): any {
      const response = yield call(remove, payload);

      return response;
    },
  },

  reducers: {
    _fetchDetail(state: any, { payload }: any) {
      return {
        ...state,
        detail: payload.data || {},
      };
    },
  },
};
