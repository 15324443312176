/*
 * @Author: your name
 * @Date: 2021-09-23 18:18:48
 * @LastEditTime: 2021-11-19 16:08:55
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /mxc-crm-3.0/src/pages/clues/models/marketCoupon.ts
 */
import {
  getUserHaveCouponList,
  revocationCoupon,
  getCoupListByRecommendList,
  sendCoupon2User,
} from '../services/marketCoupon';

export default {
  namespace: 'marketCoupon',

  state: {
    coupListByRecommendList: [], // CC给用户手动推送优惠券时显示的优惠券列表
  },

  effects: {
    * getUserHaveCouponList ({ payload }: any, { call, put }: any): any {
      const response = yield call(getUserHaveCouponList, payload);

      const {
        data = [],
      } = response || {}; // V2 的接口直接返回

      return {
        data,
        success: true,
      };
    },

    * revocationCoupon ({ payload }: any, { call, put }: any): any {
      const response = yield call(revocationCoupon, payload);

      return response;
    },

    * getCoupListByRecommendList ({ payload }: any, { call, put }: any): any {
      const response = yield call(getCoupListByRecommendList, payload);

      yield put({
        type: '_getCoupListByRecommendList',
        payload: response
      });

      return response;
    },

    * sendCoupon2User ({ payload }: any, { call, put }: any): any {
      const response = yield call(sendCoupon2User, payload);

      return response;
    },
  },

  reducers: {
    _getCoupListByRecommendList(state: any, { payload }: any) {
      return {
        ...state,
        coupListByRecommendList: payload?.data?.map?.((item: any) => {
          const {
            id,
            valid,
          } = item;

          return {
            label: valid,
            value: id,
          };
        }) || [],
      };
    },
  },
};
