/*
 * @Author: liusuyun
 * @Date: 2022-03-14 12:23:32
 * @LastEditors: liusuyun
 * @LastEditTime: 2022-03-14 17:07:43
 * @Description:获取消息接口
 */
import { getMessagePage,lookUpMessage,cleanUpMessage } from '@/services/system';

export default {
  namespace: 'system',
  state: {
    count: 0,
    messageList: [],
  },
  effects: {
    *getMessagePage({ payload }: any, { call, put }: any): any {
      const response = yield call(getMessagePage, payload);

      yield put({
        type: '_getMessagePage',
        payload: response.data,
      });

      return response.data;
    },

    *lookUpMessage({ payload }: any, { call, put }: any): any {
      const response = yield call(lookUpMessage, payload);

      return response.data;
    },

    *cleanUpMessage({ payload }: any, { call, put }: any): any {
      const response = yield call(cleanUpMessage, payload);

      return response.data;
    }

  },

  reducers: {
    _getMessagePage(state: any, { payload }: any) {
      const { count = 0, messageList = [] } = payload;

      return {
        ...state,
        count,
        messageList,
      };
    },
  },
};
