/*
 * @Author: linlianghao
 * @Date: 2021-08-12 21:45:40
 * @LastEditors: linlianghao
 * @LastEditTime: 2022-01-11 11:10:09
 * @Description:
 */
import { getChannleList } from "@/services/material";

export default {
  namespace: 'channel',
  state: {
    channelList: []
  },
  effects: {
    * getChannleList({ payload }: any, { call, put }: any): any {
      try {
        const { data } = yield call(getChannleList);
        yield put({
          type: '_setChannelList',
          payload: data
        });
      } catch (err) {
        console.error("[Channel] Get Channel List Error", err);
      }
    },
  },
  reducers: {
    _setChannelList (state: any, { payload }: any) {
      return {
        ...state,
        channelList: payload,
      };
    }
  },
};
