/*
 * @Author: your name
 * @Date: 2021-09-22 23:19:16
 * @LastEditTime: 2022-04-01 18:13:05
 * @LastEditors: liusuyun
 * @Description: leads 列表页跳转到详情页时需要传参的话可以放在这个 models 里
 */

export default {
  namespace: 'detailsPage',

  state: {
    isShowDetails: false,
    curTableRef: null,
    curTableItemIndex: 0,
    initActiveTabsKey: '',
    tagFeedback: '',

    // 未签约 leads 的详情需要在学员详情页面打开，默认情况下，还是使用上面的变量，当需要在员详情页面打开时才使用以下独立作用域下的变量
    unsignedLeadsDetails: {
      isShowDetails: false,
      curTableRef: null,
      curTableItemIndex: 0,
      initActiveTabsKey: '',
    },
  },

  effects: {},

  reducers: {
    _set(state: any, { payload }: any) {
      return {
        ...state,
        ...payload,
      };
    },

    _reset(state: any, _: any) {
      return {
        ...state,
        isShowDetails: false,
        curTableRef: null,
        curTableItemIndex: 0,
        initActiveTabsKey: '',
      };
    },

    _aloneSet(state: any, { payload }: any) {
      const { type, values } = payload;

      return {
        ...state,
        [type]: {
          ...state[type],
          ...values,
        },
      };
    },

    _aloneReset(state: any, { payload }: any) {
      const { type } = payload;
      return {
        ...state,
        [type]: {
          isShowDetails: false,
          curTableRef: null,
          curTableItemIndex: 0,
          initActiveTabsKey: '',
        },
      };
    },
  },
};
