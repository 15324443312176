export interface MaterialItem {
  id: string, // 素材ID
  campusChannelId: string, // 频道ID
  materialName: string, // 素材标题
  materialContent: string, // 素材内容
  materialSort?: number, // 排序
  materialStatus?: number, // 0启用、1禁用
  materialType: number, // 素材类型 0:图片，1：视频 2：图片+链接
  materialButton?: string, // 按钮
  materialUrl?: string, // type为2时的链接url
  materialCover?: string, // 素材视频时的封面
  updateTime?: string, // 更新时间
}

// 素材类型
export const MATERIAL_TYPE = {
  IMAGE: 0, // 图片
  VIDEO: 1, // 视频
  H5: 2 // 图片 + H5
}