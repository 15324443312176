// @ts-nocheck
import { Component } from 'react';
import { ApplyPluginsType } from 'umi';
import dva from 'dva';
// @ts-ignore
import createLoading from '/mxc/jenkins/workspace/mxc-web-omo-sit3/renderer/omo/node_modules/dva-loading/dist/index.esm.js';
import { plugin, history } from '../core/umiExports';
import ModelChannel0 from '/mxc/jenkins/workspace/mxc-web-omo-sit3/renderer/omo/src/models/channel.ts';
import ModelCoursePackage1 from '/mxc/jenkins/workspace/mxc-web-omo-sit3/renderer/omo/src/models/coursePackage.ts';
import ModelDictionary2 from '/mxc/jenkins/workspace/mxc-web-omo-sit3/renderer/omo/src/models/dictionary.ts';
import ModelGlobal3 from '/mxc/jenkins/workspace/mxc-web-omo-sit3/renderer/omo/src/models/global.ts';
import ModelLogin4 from '/mxc/jenkins/workspace/mxc-web-omo-sit3/renderer/omo/src/models/login.ts';
import ModelMaterial5 from '/mxc/jenkins/workspace/mxc-web-omo-sit3/renderer/omo/src/models/material.ts';
import ModelSystem6 from '/mxc/jenkins/workspace/mxc-web-omo-sit3/renderer/omo/src/models/system.ts';
import ModelTemplate7 from '/mxc/jenkins/workspace/mxc-web-omo-sit3/renderer/omo/src/models/template.ts';
import ModelUpdate8 from '/mxc/jenkins/workspace/mxc-web-omo-sit3/renderer/omo/src/models/update.ts';
import ModelCcLeadsBooking9 from '/mxc/jenkins/workspace/mxc-web-omo-sit3/renderer/omo/src/pages/business/leads/models/ccLeadsBooking.ts';
import ModelCcTodayTask10 from '/mxc/jenkins/workspace/mxc-web-omo-sit3/renderer/omo/src/pages/business/leads/models/ccTodayTask.ts';
import ModelLeads11 from '/mxc/jenkins/workspace/mxc-web-omo-sit3/renderer/omo/src/pages/business/leads/models/leads.ts';
import ModelLeadsCoarse12 from '/mxc/jenkins/workspace/mxc-web-omo-sit3/renderer/omo/src/pages/business/leads/models/leadsCoarse.ts';
import ModelMarketCoupon13 from '/mxc/jenkins/workspace/mxc-web-omo-sit3/renderer/omo/src/pages/business/leads/models/marketCoupon.ts';
import ModelMarketIntegration14 from '/mxc/jenkins/workspace/mxc-web-omo-sit3/renderer/omo/src/pages/business/leads/models/marketIntegration.ts';
import ModelCallOut15 from '/mxc/jenkins/workspace/mxc-web-omo-sit3/renderer/omo/src/pages/business/models/callOut.ts';
import ModelDept16 from '/mxc/jenkins/workspace/mxc-web-omo-sit3/renderer/omo/src/pages/business/models/dept.ts';
import ModelDetailsPage17 from '/mxc/jenkins/workspace/mxc-web-omo-sit3/renderer/omo/src/pages/business/models/detailsPage.ts';
import ModelMarketChannel18 from '/mxc/jenkins/workspace/mxc-web-omo-sit3/renderer/omo/src/pages/business/models/marketChannel.ts';
import ModelArrangeCourse19 from '/mxc/jenkins/workspace/mxc-web-omo-sit3/renderer/omo/src/pages/business/student/models/arrangeCourse.ts';
import ModelStudent20 from '/mxc/jenkins/workspace/mxc-web-omo-sit3/renderer/omo/src/pages/business/student/models/student.ts';
import ModelIndex21 from '/mxc/jenkins/workspace/mxc-web-omo-sit3/renderer/omo/src/pages/customer/home/models/index.ts';
import ModelIndex22 from '/mxc/jenkins/workspace/mxc-web-omo-sit3/renderer/omo/src/pages/customer/registration/models/index.ts';

let app:any = null;

export function _onCreate(options = {}) {
  const runtimeDva = plugin.applyPlugins({
    key: 'dva',
    type: ApplyPluginsType.modify,
    initialValue: {},
  });
  app = dva({
    history,
    
    ...(runtimeDva.config || {}),
    // @ts-ignore
    ...(typeof window !== 'undefined' && window.g_useSSR ? { initialState: window.g_initialProps } : {}),
    ...(options || {}),
  });
  
  app.use(createLoading());
  (runtimeDva.plugins || []).forEach((plugin:any) => {
    app.use(plugin);
  });
  app.model({ namespace: 'channel', ...ModelChannel0 });
app.model({ namespace: 'coursePackage', ...ModelCoursePackage1 });
app.model({ namespace: 'dictionary', ...ModelDictionary2 });
app.model({ namespace: 'global', ...ModelGlobal3 });
app.model({ namespace: 'login', ...ModelLogin4 });
app.model({ namespace: 'material', ...ModelMaterial5 });
app.model({ namespace: 'system', ...ModelSystem6 });
app.model({ namespace: 'template', ...ModelTemplate7 });
app.model({ namespace: 'update', ...ModelUpdate8 });
app.model({ namespace: 'ccLeadsBooking', ...ModelCcLeadsBooking9 });
app.model({ namespace: 'ccTodayTask', ...ModelCcTodayTask10 });
app.model({ namespace: 'leads', ...ModelLeads11 });
app.model({ namespace: 'leadsCoarse', ...ModelLeadsCoarse12 });
app.model({ namespace: 'marketCoupon', ...ModelMarketCoupon13 });
app.model({ namespace: 'marketIntegration', ...ModelMarketIntegration14 });
app.model({ namespace: 'callOut', ...ModelCallOut15 });
app.model({ namespace: 'dept', ...ModelDept16 });
app.model({ namespace: 'detailsPage', ...ModelDetailsPage17 });
app.model({ namespace: 'marketChannel', ...ModelMarketChannel18 });
app.model({ namespace: 'arrangeCourse', ...ModelArrangeCourse19 });
app.model({ namespace: 'student', ...ModelStudent20 });
app.model({ namespace: 'index', ...ModelIndex21 });
app.model({ namespace: 'index', ...ModelIndex22 });
  return app;
}

export function getApp() {
  return app;
}

/**
 * whether browser env
 * 
 * @returns boolean
 */
function isBrowser(): boolean {
  return typeof window !== 'undefined' &&
  typeof window.document !== 'undefined' &&
  typeof window.document.createElement !== 'undefined'
}

export class _DvaContainer extends Component {
  constructor(props: any) {
    super(props);
    // run only in client, avoid override server _onCreate()
    if (isBrowser()) {
      _onCreate()
    }
  }

  componentWillUnmount() {
    let app = getApp();
    app._models.forEach((model:any) => {
      app.unmodel(model.namespace);
    });
    app._models = [];
    try {
      // 释放 app，for gc
      // immer 场景 app 是 read-only 的，这里 try catch 一下
      app = null;
    } catch(e) {
      console.error(e);
    }
  }

  render() {
    let app = getApp();
    app.router(() => this.props.children);
    return app.start()();
  }
}
