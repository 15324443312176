/*
 * @Author: your name
 * @Date: 2021-09-22 23:19:38
 * @LastEditTime: 2022-01-05 14:57:35
 * @LastEditors: wuqinfa
 * @Description: In User Settings Edit
 */
import request from '@/utils/request';

/**
 * 数据列表
 */
export async function tableData(params: any) {
  return request('/crm-account/account/getAccountList', {
    method: 'GET',
    data: params,
  });
}

/**
 * 数据详情
 */
export async function dataDetail(params: any) {
  return request('/crm-account/account/getAccount', {
    method: 'GET',
    data: params,
  });
}

/**
 * 新增
 */
export async function add(params: any) {
  return request('/crm-account/account/addAccount', {
    method: 'POST',
    data: params
  });
}

/**
 * 修改
 */
export async function update(params: any) {
  return request('/crm-account/account/updateAccount', {
    method: 'POST',
    data: params
  });
}

/**
 * 删除
 */
export async function remove(params: any) {
  return request('/crm-account/account/updateAccount', {
    method: 'POST',
    data: params
  });
}
