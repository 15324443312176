import * as Api from '../services/index';
import { getToday } from '@/utils/date';

export default {
  namespace: 'appointmentList',
  state: {
    dateList: [],
    list: []
  },
  effects: {
    // 获取预约到店列表
    *getTime({ payload, callback }: any, { call, put }: any): any {
      const currentDate = getToday()[0].value;
      const res = yield call(Api.getTime, payload);
      const newList = (res.data || []).map((v: any) => {
        const d = v.date.split('-')
        const year = d[0]
        const month = d[1]
        const day = d[2]
        return {
          year,
          month,
          day,
          value: v.date,
          label: currentDate === v.date ? '今日' : `${day}日`
        }
      })
      yield put({
        type: '_changeState',
        payload: {
          dateList: newList
        },
      });
      callback && callback(newList)
    },
    // 获取预约到店列表
    *getAttendStoreList({ payload, extra }: any, { call, put }: any): any {
      const res = yield call(Api.getAttendStoreList, payload);
      yield put({
        type: '_changeState',
        payload: {
          list: res.data
        },
      });
    },
    // 确认到店
    *updateConfirmStore({ payload, callback }: any, { call, put }: any): any {
      const res = yield call(Api.updateConfirmStore, payload);
      console.log('updateConfirmStore', res)
      callback && callback(res)
    },
  },
  reducers: {
    _changeState(state: any, { payload }: any) {
      return {
        ...state,
        ...payload
      };
    },
  },
};

