/*
 * @Author: your name
 * @Date: 2021-09-22 23:19:16
 * @LastEditTime: 2022-01-19 18:48:50
 * @LastEditors: wuqinfa
 * @Description: 毛leads
 */
import {
  tableData,
  dataDetail,
  update,
  remove,
  addMyLeads,
  remark,
  getPhone,
  getFollowRecord,
} from '../services/leadsCoarse';

export default {
  namespace: 'leadsCoarse',

  state: {
    detail: {}, // 毛 leads 数据详情
    leadsCoarse: [], // 毛 leads 列表
    followRecord: [], // 毛leads的跟进记录
  },

  effects: {
    * tableData({ payload, extra }: any, { call, put }: any): any {
      const response = yield call(tableData, payload);

      const {
        records = [],
        current = 1,
        size = 10,
        total = 0,
      } = response.data || {};


      yield put({
        type: '_tableData',
        payload: records,
      });

      // ProTable 的 request 属性，需要返回特定数据结构的 Promise 对象
      return {
        data: records,
        total,
        success: true,
        page: current,
        pageSize: size,
      };
    },

    * dataDetail ({ payload }: any, { call, put }: any): any {
      const response = yield call(dataDetail, payload);

      yield put({
        type: '_dataDetail',
        payload: response
      });

      return response;
    },

    * update ({ payload }: any, { call, put }: any): any {
      const response = yield call(update, payload);

      return response;
    },

    * remove ({ payload }: any, { call, put }: any): any {
      const response = yield call(remove, payload);

      return response;
    },

    * addMyLeads ({ payload }: any, { call, put }: any): any {
      const response = yield call(addMyLeads, payload);

      return response;
    },

    * remark ({ payload }: any, { call, put }: any): any {
      const response = yield call(remark, payload);

      return response;
    },

    *getPhone({ payload }: any, { call, put }: any): any {
      const response = yield call(getPhone, payload);

      return response;
    },

    *getFollowRecord({ payload }: any, { call, put }: any): any {
      const response = yield call(getFollowRecord, payload);

      yield put({
        type: '_getFollowRecord',
        payload: response,
      });

      return response;
    },
  },

  reducers: {
    _dataDetail(state: any, { payload }: any) {
      return {
        ...state,
        detail: payload.data || {},
      };
    },

    _tableData(state: any, { payload }: any) {
      return {
        ...state,
        leadsCoarse: payload,
      };
    },

    _getFollowRecord(state: any, { payload }: any) {
      return {
        ...state,
        followRecord: payload.data || [],
      };
    },

  },
};
