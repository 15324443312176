/*
 * @Author: your name
 * @Date: 2021-09-22 23:19:38
 * @LastEditTime: 2022-02-16 15:52:45
 * @LastEditors: liusuyun
 * @Description: In User Settings Edit
 * @FilePath: /mxc-crm-3.0/src/services/template.ts
 */
import request from '@/utils/request';

/**
 * 渠道树状查询（leads来源/渠道层级）
 */
export async function listTreeChannel(params: any) {
  return request('/channel/channel_type_tree', {
    method: 'GET',
    data: params,
    prefix: API_URL_V2
  });
}

/**
 * 根据key关键字查询渠道编码
 */
export async function getAllChannel(params: any) {
  return request('/leads/getAllChannel', {
    method: 'GET',
    data: params,
    prefix: API_URL_V2
  });
}

/**
 * 获取所有渠道列表
 */
export async function getChannelList(params: any) {
  return request('/channel/getChannelList', {
    method: 'GET',
    data: params,
    prefix: API_URL_V2
  });
}

// 获取所有地推活动
export async function listAll(params: any) {
  return request('/crm-market/market/pushActivity/listAll', {
    method: 'GET',
    data: params
  });
}
