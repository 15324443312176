/*
 * @Author: your name
 * @Date: 2021-09-22 23:19:38
 * @LastEditTime: 2022-01-19 18:48:30
 * @LastEditors: wuqinfa
 * @Description: 毛leads
 */
import request from '@/utils/request';

/**
 * 毛leads列表（Surface）
 */
export async function tableData(params: any) {
  return request('/crm-market/leadsCoarse/workbenchPage', {
    method: 'POST',
    data: params,
  });
}

/**
 * 毛leads详情
 */
export async function dataDetail(params: any) {
  return request('/crm-market/leadsCoarse/detail', {
    method: 'GET',
    data: params,
  });
}

/**
 * 修改毛leads详情（Surface）
 */
 export async function update(params: any) {
  return request('/crm-market/leadsCoarse/update', {
    method: 'POST',
    data: params
  });
}

/**
 * 毛leads移除（Surface）
 */
 export async function remove(params: any) {
  return request('/crm-market/leadsCoarse/remove', {
    method: 'POST',
    data: params,
    requestType: 'form',
  });
}

/**
 * 毛leads添加我的leads（Surface）
 */
 export async function addMyLeads(params: any) {
  return request('/crm-market/leadsCoarse/addMyLeads', {
    method: 'POST',
    data: params,
    requestType: 'form',
  });
}

/**
 * 毛leads记录（Surface）
 */
 export async function remark(params: any) {
  return request('/crm-market/leadsCoarse/remark', {
    method: 'POST',
    data: params,
  });
}

/**
 * 毛 leads 根据leadsID查询手机号
 */
 export async function getPhone(params: any) {
  return request('/crm-market/leadsCoarse/getPhone', {
    method: 'GET',
    data: params,
  });
}

/**
 * 毛leads记录列表
 */
 export async function getFollowRecord(params: any) {
  return request('/crm-market/leadsCoarse/remarkList', {
    method: 'GET',
    data: params,
  });
}



