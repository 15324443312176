/*
 * @Author: linlianghao
 * @Date: 2021-08-12 21:45:40
 * @LastEditors: linlianghao
 * @LastEditTime: 2022-01-11 11:10:09
 * @Description:
 */

export default {
  namespace: 'update',
  state: {
    available: false,
    forceVersion: "1.0.0",
    notice: false,
    updateInfo: [],
    currentVersion: '',
    version: '',
  },
  effects: {
  },
  reducers: {
    _setUpdateInfo(state: any, { payload }: any) {
      return payload;
    }
  },
};
