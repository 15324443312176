/*
 * @Author: your name
 * @Date: 2021-09-22 23:19:38
 * @LastEditTime: 2022-03-28 15:28:07
 * @LastEditors: liusuyun
 * @Description: CC 工作台-今日推荐任务
 */
import request from '@/utils/request';

/**
 * CC 工作台 - 今日推荐任务 - 新leads - 新leads
 */
export async function newLeads(params: any) {
  return request('/crm-market/ccTodayTask/task/newLeads', {
    method: 'GET',
    data: params,
  });
}

/**
 * CC 工作台 - 今日推荐任务 - 新leads - 公海leads
 */
export async function listFromSeaLeads(params: any) {
  return request('/crm-market/ccTodayTask/task/listFromSeaLeads', {
    method: 'GET',
    data: params,
  });
}

/**
 * CC 工作台 - 今日推荐任务 - 今日出席促单任务
 */
export async function todayPresentOrder(params: any) {
  return request('/crm-market/ccTodayTask/task/todayPresentOrder', {
    method: 'GET',
    data: params,
  });
}

/**
 * CC 工作台 - 今日推荐任务 - 五日内未签单
 */
export async function fiveNoOrder(params: any) {
  return request('/crm-market/ccTodayTask/task/fiveNoOrder', {
    method: 'GET',
    data: params,
  });
}

/**
 * CC 工作台 - 今日推荐任务 - 待出席确认（出席未确认）
 */
export async function presentNoConfirm(params: any) {
  return request('/crm-market/ccTodayTask/task/presentNoConfirm', {
    method: 'GET',
    data: params,
  });
}

/**
 * CC 工作台 - 今日推荐任务 - 未按时出席
 */
export async function noOnTimePresent(params: any) {
  return request('/crm-market/ccTodayTask/task/noOnTimePresent', {
    method: 'GET',
    data: params,
  });
}

/**
 * CC 工作台 - 今日推荐任务 - 五日内未约课
 */
export async function fiveOrderCourse(params: any) {
  return request('/crm-market/ccTodayTask/task/fiveOrderCourse', {
    method: 'GET',
    data: params,
  });
}

/**
 * CC 工作台 - 今日推荐任务 - 今日待办
 */
export async function todayBacklog(params: any) {
  return request('/crm-market/ccTodayTask/task/todayBacklog', {
    method: 'GET',
    data: params,
  });
}

/**
 * CC 工作台 - 今日推荐任务 - 未分配
 */
export async function getUnAllocate(params: any) {
  return request('/crm-market/leadsExtension/getUnAllocate', {
    method: 'GET',
    data: params,
  });
}

/**
 * CC 工作台 - 今日推荐任务 - 顶部各类型 table 标签栏的数据统计值
 */
export async function getCount(params: any) {
  return request('/crm-market/ccTodayTask/task/getCount', {
    method: 'GET',
    data: params,
  });
}

/**
 * CC 工作台 - 获取CC对某个leads今日任务完成情况列表(跟进弹窗中的“今日任务”卡片简介)
 */
export async function getTaskProgressByLeadsId(params: any) {
  return request('/crm-market/ccTodayTask/getTaskProgressByLeadsId', {
    method: 'GET',
    data: params,
  });
}

/**
 * CC 工作台 - 检查leads是否流转
 */
export async function doCheckLeads(params: any) {
  return request('/crm-market/ccTodayTask/doCheckLeads', {
    method: 'GET',
    data: params,
  });
}

/**
 * CC 工作台 - 获取试听课报告
 */
export async function getUserLessonId(params: any) {
  return request('/course/getUserLessonId', {
    method: 'GET',
    data: params,
    prefix: API_URL_V2,
  });
}

/**
 * CC 工作台 - 获取老师反馈
 */
export async function getDemoFeedBackInfo(params: any) {
  return request('/course/getDemoFeedBackInfo', {
    data: params,
    prefix: API_URL_V2,
  });
}

/**
 * CC 工作台 - 获取试听课学习报告链接
 */
export async function getDemoPreviewUrl(params: any) {
  return request('/crm-teaching/leadsBooking/getDemoPreviewUrl', {
    data: params,
  });
}

/**
 * CC 工作台 - 获取推荐商品列表
 */
export async function getRecommendGoods(params: any) {
  return request('/crm-order/recommendUserCourse/getRecommendGoods', {
    data: params,
  });
}

/**
 * CC 工作台 - 推送给用户
 */
export async function sendUserGoods(params: any) {
  return request('/crm-order/recommendUserCourse/sendUserGoods', {
    data: params,
  });
}

/**
 * CC 工作台 - 用户已推送的商品
 */
export async function getUserRecommendGoods(params: any) {
  return request('/crm-order/recommendUserCourse/getUserRecommendGoods', {
    data: params,
  });
}

/**
 * CC 工作台 - leads分配
 */
export async function allocate(params: any) {
  if (params.selectType === 'DEPART') {
    return request('/crm-market/leads/allocateToDept', {
      method: 'POST',
      data: params,
    });
  } else {
    return request('/crm-market/leads/allocate', {
      method: 'POST',
      data: params,
    });
  }
}
