/*
 * @Author: your name
 * @Date: 2021-09-23 18:18:48
 * @LastEditTime: 2021-11-19 16:16:00
 * @LastEditors: Please set LastEditors
 * @Description: 票券管理
 * @FilePath: /mxc-crm-3.0/src/pages/clues/services/marketCoupon.ts
 */
import request from '@/utils/request';

/**
 * 票券的列表数据
 */
export async function getUserHaveCouponList(params: any) {
  return request('/coupon/getUserHaveCouponList', {
    method: 'GET',
    data: params,
    prefix: API_URL_V2,
  });
}

/**
 * 撤销票券
 */
export async function revocationCoupon(params: any) {
  return request('/coupon/revocationCoupon', {
    method: 'GET',
    data: params,
    prefix: API_URL_V2,
  });
}


/**
 * 获取CC给用户手动推送优惠券时显示的优惠券列表
 */
export async function getCoupListByRecommendList(params: any) {
  return request('/crm-user/coupon/getCoupListByRecommendList', {
    method: 'GET',
    data: params,
  });
}

/**
 * 推送优惠券给指定用户
 */
export async function sendCoupon2User(params: any) {
  return request('/crm-user/coupon/sendCoupon2User', {
    method: 'POST',
    data: params,
    requestType: 'form',
  });
}
