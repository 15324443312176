/*
 * @Author: wuqinfa
 * @Date: 2021-09-22 23:19:16
 * @LastEditTime: 2022-01-18 10:55:08
 * @LastEditors: wuqinfa
 * @Description: 外呼
 */
import {
  callOut,
  leadsCoarseCallOut,
  callCancel,
} from '../services/callOut';

export default {
  namespace: 'callOut',

  state: {
    // 根据这 leadsId 判断当前哪个处于外呼状态
    curCallingLeadsId: null, // 当前 外呼 的 leadsId

    curCallId: null, // 当前链接 websocket 的 id，如果这个存在，说明已经发起了一个外呼，控制全局只能发起一个外呼
  },

  effects: {
    * callOut ({ payload }: any, { call, put }: any): any {
      const {
        leadsId,
      } = payload;

      const response = yield call(callOut, payload);

      yield put({
        type: '_setCurId',
        payload: {
          curCallingLeadsId: leadsId,
          curCallId: response.data,
        }
      });

      return response;
    },

    * leadsCoarseCallOut ({ payload }: any, { call, put }: any): any {
      const {
        leadsId,
      } = payload;

      const response = yield call(leadsCoarseCallOut, payload);

      yield put({
        type: '_setCurId',
        payload: {
          curCallingLeadsId: leadsId,
          curCallId: response.data,
        }
      });

      return response;
    },

    * callCancel ({ payload }: any, { call, put }: any): any {
      const response = yield call(callCancel, payload);

      yield put({
        type: '_resetCurId',
      });

      return response;
    },
  },

  reducers: {
    _setCurId(state: any, { payload }: any) {
      const {
        curCallingLeadsId,
        curCallId,
      } = payload;

      return {
        ...state,
        curCallingLeadsId,
        curCallId,
      };
    },

    _resetCurId(state: any) {
      return {
        ...state,
        curCallingLeadsId: null,
        curCallId: null,
      };
    },
  },
};
