/*
 * @Author: your name
 * @Date: 2021-09-22 23:19:16
 * @LastEditTime: 2022-03-15 18:25:17
 * @LastEditors: liusuyun
 * @Description:
 */
import {
  listAllUnsignedLeads,
  getPhone,
  details,
  listAllCity,
  updateLeads,
  updateUserById,
  getLeadsCirculation,
  getFollowRecord,
  getInvitedUsersVO,
  formalCallbackHistoryDetail,
  getDemoFeedBackInfo,
  addRemark,
  updateNextFollowTime,
  getLeadsQuestionnaire,
  getUserLessonId,
  getDemoPreviewUrl,
  getDemoRoom,
  getLeadsFollowCount,
  listPublicSea,
  addLeads,
  pickLeads,
  updateLeadsOtherInfo,
  doCheckLeads,
  getGoods,
  getCouponList,
  getExchangeRuleByGoodsId,
  getUserId,
  getPayUrl,
  getForceDeviceCheckDetails,
  getCcList,
  getPusherList,
  getCcGenPoster,
  generatePoster,
  lockOrUnlock,
  saveWhiteLeads,
} from '../services/leads';

export default {
  namespace: 'leads',

  state: {
    detail: {}, // leads数据详情
    listAllCity: [],

    listAllUnsignedLeads: [], // 未签约leads列表
    // 未签约leads统计
    unsignedLeadsStat: {
      dyingCount: 0, // 即将掉库数
      waitAttend: 0, // 约课待出席数
      unAttend: 0, // 约课未出席数
      attendedCount: 0, // 约课已出席数
      allCount: 0, // 全部人数
      unscheduled: 0, // 未约课数
      focusCount: 0, // 特别关注
      toDoCount: 0, // 今日待办
      newLeadsCount: 0, // 新Lead
      todayAttendedCount: 0, // 今日出席
    },

    listPublicSea: [], // 公海 leads 数据列表

    followRecord: [], // leads的跟进记录

    formalCallbackHistoryDetail: {}, // 正式课老师回访内容

    leadsQuestionnaire: {}, // 记录 - 用户登记信息

    ccList: [], // 所有销售人员

    pusherList: [], // 所有在职推广人员
  },

  effects: {
    *listAllUnsignedLeads({ payload }: any, { call, put }: any): any {
      const response = yield call(listAllUnsignedLeads, payload);

      const { page = {}, stat = {} } = response.data || {};
      const { records = [], current = 1, size = 10, total = 0 } = page;

      yield put({
        type: '_listAllUnsignedLeads',
        payload: {
          list: records,
          stat,
        },
      });

      // ProTable 的 request 属性，需要返回特定数据结构的 Promise 对象
      return {
        data: records,
        success: true,
        total,
        page: current,
        pageSize: size,
      };
    },

    *getPhone({ payload }: any, { call, put }: any): any {
      const response = yield call(getPhone, payload);

      return response;
    },

    *details({ payload }: any, { call, put }: any): any {
      const response = yield call(details, payload);

      yield put({
        type: '_details',
        payload: response,
      });

      return response;
    },

    *updateLeads({ payload }: any, { call, put }: any): any {
      const response = yield call(updateLeads, payload);

      return response;
    },

    *updateUserById({ payload }: any, { call, put }: any): any {
      const response = yield call(updateUserById, payload);

      return response;
    },

    *listAllCity({ payload }: any, { call, put }: any): any {
      const response = yield call(listAllCity, payload);

      yield put({
        type: '_listAllCity',
        payload: response,
      });

      return response;
    },

    *getLeadsCirculation({ payload }: any, { call, put }: any): any {
      const response = yield call(getLeadsCirculation, payload);

      return response;
    },

    *getFollowRecord({ payload }: any, { call, put }: any): any {
      const response = yield call(getFollowRecord, payload);

      yield put({
        type: '_getFollowRecord',
        payload: response,
      });

      return response;
    },

    *getInvitedUsersVO({ payload }: any, { call, put }: any): any {
      const response = yield call(getInvitedUsersVO, payload);

      const { data = {} } = response || {};
      const { userVO = {}, list = [] } = data;

      yield put({
        type: '_getInvitedUsersVO',
        payload: userVO,
      });

      return {
        data: list,
        success: true,
      };
    },

    *formalCallbackHistoryDetail({ payload }: any, { call, put }: any): any {
      const response = yield call(formalCallbackHistoryDetail, payload);

      yield put({
        type: '_formalCallbackHistoryDetail',
        payload: response,
      });

      return response;
    },

    *getDemoFeedBackInfo({ payload }: any, { call, put }: any): any {
      const response = yield call(getDemoFeedBackInfo, payload);

      return response;
    },

    *addRemark({ payload }: any, { call, put }: any): any {
      const response = yield call(addRemark, payload);

      return response;
    },

    *updateNextFollowTime({ payload }: any, { call }: any): any {
      const response = yield call(updateNextFollowTime, payload);

      return response;
    },

    *getLeadsQuestionnaire({ payload }: any, { call, put }: any): any {
      const response = yield call(getLeadsQuestionnaire, payload);

      yield put({
        type: '_getLeadsQuestionnaire',
        payload: response,
      });

      return response;
    },

    *getUserLessonId({ payload }: any, { call, put }: any): any {
      const response = yield call(getUserLessonId, payload);

      return response;
    },

    *getDemoPreviewUrl({ payload }: any, { call, put }: any): any {
      const response = yield call(getDemoPreviewUrl, payload);

      return response;
    },

    *getDemoRoom({ payload }: any, { call }: any): any {
      const response = yield call(getDemoRoom, payload);

      return response;
    },

    *getLeadsFollowCount({ payload }: any, { call, put }: any): any {
      const response = yield call(getLeadsFollowCount, payload);

      const { data = [] } = response || {};

      return {
        data,
        success: true,
      };
    },

    *listPublicSea({ payload, extra }: any, { call, put }: any): any {
      const response = yield call(listPublicSea, payload);

      yield put({
        type: '_listPublicSea',
        payload: response,
      });

      const {
        records = [],
        current = 1,
        pages = 0,
        size = 10,
        total = 0,
      } = response.data || {};

      // ProTable 的 request 属性，需要返回特定数据结构的 Promise 对象
      return {
        data: records,
        total,
        success: true,
        page: current,
        pageSize: size,
      };
    },

    *addLeads({ payload }: any, { call, put }: any): any {
      const response = yield call(addLeads, payload);

      return response;
    },

    *pickLeads({ payload }: any, { call, put }: any): any {
      const response = yield call(pickLeads, payload);

      return response;
    },

    *updateLeadsOtherInfo({ payload }: any, { call, put }: any): any {
      const response = yield call(updateLeadsOtherInfo, payload);

      return response;
    },

    *doCheckLeads({ payload }: any, { call, put }: any): any {
      const response = yield call(doCheckLeads, payload);

      return response;
    },

    // 获取商品信息，用于收款
    *getGoods({ payload }: any, { call, put }: any): any {
      const response = yield call(getGoods, payload);
      return response;
    },

    // 获取商品对应的优惠券
    *getCouponList({ payload }: any, { call, put }: any): any {
      const response = yield call(getCouponList, payload);
      return response;
    },

    // 获取商品对应的优惠券
    *getExchangeRuleByGoodsId({ payload }: any, { call, put }: any): any {
      const response = yield call(getExchangeRuleByGoodsId, payload);
      return response;
    },

    // 获取商品对应的优惠券
    *getUserId({ payload }: any, { call, put }: any): any {
      const response = yield call(getUserId, payload);
      return response;
    },

    // 支付链接
    *getPayUrl({ payload }: any, { call, put }: any): any {
      const response = yield call(getPayUrl, payload);
      return response;
    },

    *getForceDeviceCheckDetails({ payload }: any, { call }: any): any {
      const response = yield call(getForceDeviceCheckDetails, payload);

      return response;
    },

    *getCcList({ payload, extra }: any, { call, put }: any): any {
      const response = yield call(getCcList, payload);

      yield put({
        type: '_getCcList',
        payload: response.data,
      });

      return response.data;
    },

    *getPusherList({ payload, extra }: any, { call, put }: any): any {
      const response = yield call(getPusherList, payload);

      yield put({
        type: '_getPusherList',
        payload: response.data,
      });

      return response.data;
    },

    *getCcGenPoster({ payload }: any, { call }: any): any {
      const response = yield call(getCcGenPoster, payload);

      return response;
    },

    *generatePoster({ payload }: any, { call }: any): any {
      const response = yield call(generatePoster, payload);

      return response;
    },

    *lockOrUnlock({ payload }: any, { call, put }: any): any {
      const response = yield call(lockOrUnlock, payload);
      return response;
    },

    *saveWhiteLeads({ payload }: any, { call }: any): any {
      const response = yield call(saveWhiteLeads, payload);

      return response;
    },
  },

  reducers: {
    _listAllUnsignedLeads(state: any, { payload }: any) {
      const { list = [], stat = {} } = payload;

      return {
        ...state,
        listAllUnsignedLeads: list,
        unsignedLeadsStat: stat,
      };
    },

    _details(state: any, { payload }: any) {
      return {
        ...state,
        detail: payload.data || {},
      };
    },

    _listAllCity(state: any, { payload }: any) {
      const { data = [] } = payload || {};

      return {
        ...state,
        listAllCity:
          data?.map?.((item, index) => {
            const { code, name } = item;

            return {
              label: name,
              value: code,
            };
          }) || [],
      };
    },

    _getFollowRecord(state: any, { payload }: any) {
      return {
        ...state,
        followRecord: payload.data || [],
      };
    },

    _formalCallbackHistoryDetail(state: any, { payload }: any) {
      return {
        ...state,
        formalCallbackHistoryDetail: payload.data || {},
      };
    },

    _getLeadsQuestionnaire(state: any, { payload }: any) {
      return {
        ...state,
        leadsQuestionnaire: payload.data || {},
      };
    },

    _listPublicSea(state: any, { payload }: any) {
      const { records = [] } = payload.data || {};

      return {
        ...state,
        listPublicSea: records,
      };
    },

    _getCcList(state: any, { payload }: any) {
      const { ccList = [] } = payload.data || {};
      return {
        ...state,
        ccList,
      };
    },

    _getInvitedUsersVO(state: any, { payload }: any) {
      return {
        ...state,
        invitedUsersVO: payload || {},
      };
    },

    _getPusherList(state: any, { payload }: any) {
      const { pusherList = [] } = payload.data || {};
      return {
        ...state,
        pusherList,
      };
    },
  },
};
