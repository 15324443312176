/*
 * @Author: liusuyun
 * @Date: 2022-02-28 17:33:21
 * @LastEditors: liusuyun
 * @LastEditTime: 2022-03-28 15:35:47
 * @Description:
 */
import * as api from '@/pages/business/services/dept';
import lodash from 'lodash';

const splitChildNull = (data: Array<any>) => {
  data.forEach((ele, index: number) => {
    if (!ele.children) {
      ele = lodash.omit(ele, ['children']);
      data[index] = ele;
    } else if (ele.children.length) {
      splitChildNull(ele.children);
    }
  });
};

export default {
  namespace: 'dept',
  state: { listChildrenTreeByAccount: [] }, // 当前账号所属组织下的组织架构

  effects: {
    *listChildrenTreeByAccount({ payload }: any, { call, put }: any): any {
      const response = yield call(api.listChildrenTreeByAccount, payload);
      splitChildNull(response.data);

      yield put({
        type: '_listChildrenTreeByAccount',
        payload: response,
      });

      return response;
    },

    * listAllocateOfCC ({ payload }: any, { call, put }: any): any {
    const response = yield call(api.listAllocateOfCC, payload);

    // ProTable 的 request 属性，需要返回特定数据结构的 Promise 对象
    return {
      data: response.data || [],
      success: true,
    };
  },
  },

  reducers: {
    _listChildrenTreeByAccount(state: any, { payload }: any) {
      return {
        ...state,
        listChildrenTreeByAccount: payload.data || [],
      };
    },
  },
};

// export default Dept;
