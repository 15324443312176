/*
 * @Author: your name
 * @Date: 2021-09-22 23:19:38
 * @LastEditTime: 2022-02-14 14:14:02
 * @LastEditors: wuqinfa
 * @Description: leads 详情页 - 约课
 */
import request from '@/utils/request';

/**
 * 约课
 */
 export async function booking(params: any) {
  return request('/crm-teaching/leadsBooking/booking/demo', {
    method: 'POST',
    data: params,
    requestType: 'form',
  });
}


/**
 * 获取约课记录列表
 */
 export async function getBookingRecord(params: any) {
  return request('/crm-teaching/leadsBooking/booking/record', {
    method: 'GET',
    data: params,
  });
}


/**
 * 取消预约
 */
 export async function cancelBooking(params: any) {
  return request('/crm-teaching/leadsBooking/cancel/booking', {
    method: 'POST',
    data: params,
    requestType: 'form',
  });
}


/**
 * 改约
 */
 export async function changeBooking(params: any) {
  return request('/crm-teaching/leadsBooking/change/booking', {
    method: 'POST',
    data: params,
    requestType: 'form',
  });
}


/**
 * 获取试听课约课池
 */
export async function getBookingPool(params: any) {
  return request('/crm-teaching/leadsBooking/getBookingPool', {
    method: 'GET',
    data: params,
  });
}

/**
 * 获取个人当前约课信息
 */
 export async function getUserCurrentBooking(params: any) {
  return request('/crm-teaching/leadsBooking/getUserCurrentBooking', {
    method: 'GET',
    data: params,
  });
}

/**
 * 毛leads约课（Surface）
 */
 export async function leadsCoarseBooking(params: any) {
  return request('/crm-market/leadsCoarse/demoCourse', {
    method: 'POST',
    data: params,
    requestType: 'form',
  });
}

/**
 * 获取用户是否重度出席当前课程类型试听课
 */
export async function hadSevereAttend(params: any) {
  return request('/crm-teaching/leadsBooking/hadSevereAttend', {
    method: 'GET',
    data: params,
  });
}


