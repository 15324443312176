/*
 * @Author: linlianghao
 * @Date: 2021-08-12 21:45:40
 * @LastEditors: linlianghao
 * @LastEditTime: 2022-01-11 11:10:09
 * @Description:
 */
import { getMaterialList } from "@/services/material";
import MaterialService from "@/utils/materialService";
import { MaterialItem, MATERIAL_TYPE } from "@/pages/customer/material/interface";

export default {
  namespace: 'material',
  state: {
    materialList: []
  },
  effects: {
    *getMaterialList({ payload }: any, { call, put }: any): any {
      try {
        const { campusChannelId,  canUseCache } = payload
        const opt = { campusChannelId };
        const { data } = yield call(getMaterialList, opt);

        // 替换资源（查询是否有本地缓存）
        let newData: MaterialItem[] = [];
        for (let i = 0; i < data.length; i ++) {
          const item = data[i];
          const { materialType } = item;
          const isVideo = (materialType === MATERIAL_TYPE.VIDEO);

          if ( canUseCache) {
            const { id, campusChannelId, materialName, materialContent, updateTime } = item;
            const opt = {
              item: { id, campusChannelId, materialName, materialContent, updateTime },
              type: isVideo ? MATERIAL_TYPE.VIDEO : MATERIAL_TYPE.IMAGE, // 图片素材和H5素材的materialContent都是图片，因此都归类成图片了
            };
            const res = yield call(MaterialService.getLocalCache, opt);
            item.materialContent = res;
          }
          newData.push(item);
        }

        yield put({
          type: '_setMaterialList',
          payload: newData
        });
      } catch (err) {
        console.error("[Material] Get Material List Error", err);
      }
    },
  },
  reducers: {
    _setMaterialList (state: any, { payload }: any) {
      return {
        ...state,
        materialList: payload,
      };
    }
  },
};
