/*
 * @Author: liusuyun
 * @Date: 2021-08-12 21:45:40
 * @LastEditors: liusuyun
 * @LastEditTime: 2022-03-29 09:52:46
 * @Description:
 */
import { login, logout, changePassword, getCurrentAccount, getDetail, } from '@/services/account';

import * as cache from '@/utils/cache';
import log from '@/utils/log';

export default {
  namespace: 'login',
  state: {
    account: {}, // 当前用户的账户信息
  },
  effects: {
    *login({ payload }: any, { call }: any): any {
      const response = yield call(login, payload);

      cache.set('mxc-token', response.data);

      return response;
    },

    *logout(_: any, { call, put }: any): any {
      const response = yield call(logout);

      return response;
    },

    *changePassword({ payload }: any, { call }: any) {
      yield call(changePassword, payload);
    },

    *getCurrentAccount({ payload }: any, { call, put }: any): any {
      const response = yield call(getCurrentAccount, payload);

      localStorage.setItem('currentUser', JSON.stringify(response.data))

      log.info('user', `getCurrentUser: ${JSON.stringify(response.data.account)}`)

      return response;
    },

    *getDetail({ payload }: any, { call, put }: any): any {
      const response = yield call(getDetail, payload);

      yield put({
        type: '_getDetail',
        payload: response
      });

      return response;
    },
  },
  reducers: {
    _getDetail(state: any, { payload }: any) {
      return {
        ...state,
        account: payload.data || {},
      };
    },
  },
};
