/*
 * @Author: liusuyun
 * @Date: 2021-08-27 16:57:36
 * @LastEditors: liusuyun
 * @LastEditTime: 2022-03-29 14:10:39
 * @Description: 全局 store
 */
import { getCurrentAccount } from '@/services/account';
import lodash from 'lodash';
const formatMxcAuthList = (mxcAuthList: []): string[] => {
  const result: string[] = [];

  mxcAuthList?.forEach((item) => {
    const { value = [] } = item;

    result.push(...value);
  });

  return result;
};
const ADMIN_SIGN = '管理员';
export default {
  namespace: 'global',
  state: {
    test: false,
    loading: false,
    mxcAuthList: [], // 当前用户的角色对应拥有的权限值，用于鉴权
    isAdmin: false,
  },
  effects: {
    *getCurrentAccount({ payload }: any, { call, put }: any): any {
      const response = yield call(getCurrentAccount);
      const { mxcAuthList = [], roleList = [] } = response.data || {};
      const isAdmin = lodash.find(roleList, { roleName: ADMIN_SIGN });
      yield put({
        type: '_getCurrentAccount',
        payload: { mxcAuthList: formatMxcAuthList(mxcAuthList), isAdmin },
      });
      // console.log(formatMxcAuthList(mxcAuthList));
    },
  },
  reducers: {
    _showLoading(state: any) {
      return {
        ...state,
        loading: true,
      };
    },
    _hideLoading(state: any) {
      return {
        ...state,
        loading: false,
      };
    },
    _getCurrentAccount(state: any, { payload }: any) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};
