/*
 * @Author: your name
 * @Date: 2021-09-22 23:19:38
 * @LastEditTime: 2022-01-06 14:57:35
 * @LastEditors: linlianghao
 * @Description: In User Settings Edit
 */
import request from '@/utils/request';

/**
 * 获取频道列表
 */
export async function getChannleList() {
  return request('/crm-market/campus/channel/listUsingCampusChannel', {
    method: 'GET'
  });
}

/**
 * 获取素材列表
 */
 export async function getMaterialList(params: any) {
  return request('/crm-market/campus/channel/listUsingMaterialByCampusChannel', {
    method: 'GET',
    data: params,
  });
}

/**
 * 获取缓存素材
 */
 export async function getAllVideo(params: any) {
  return request('/crm-market/campus/channel/getAllVideo', {
    method: 'GET',
    data: params
  });
}

/**
 * 获取缓存课程包
 */
 export async function getGoodsCache() {
  return request('/crm-order/goods/listOmoGoodsCache', {
    method: 'GET'
  });
}

/**
 * 获取课程包（商品）列表
 */
 export async function getCoursePackageList() {
  return request('/crm-order/goods/listOmoGoods', {
    method: 'GET'
  });
}

/**
 * 判断leads归属
 */
 export async function checkLeads(params: any) {
  return request('/crm-market/leads/walk/checkLeads', {
    method: 'GET',
    data: params,
  });
}
