import request from '@/utils/request';

/**
 * 获取当前登录人约课日期
 */
 export async function getTime(params: any) {
  return request('/crm-market/leads/walk/getTime', {
    method: 'GET',
    data: params,
  });
}

/**
 * 获取预约到店列表
 */
export async function getAttendStoreList(params: any) {
  return request('/crm-market/leads/walk/getAttendStoreList', {
    method: 'GET',
    data: params,
  });
}

/**
 * 确认到店
 */
 export async function updateConfirmStore(params: any) {
  return request('/crm-market/leads/walk/updateConfirmStore', {
    method: 'POST',
    data: params,
  });
}
