/*
 * @Author: your name
 * @Date: 2021-09-22 23:19:16
 * @LastEditTime: 2022-03-29 17:56:56
 * @LastEditors: liusuyun
 * @Description: CC 工作台-今日推荐任务
 */
import {
  newLeads,
  listFromSeaLeads,
  todayPresentOrder,
  fiveNoOrder,
  presentNoConfirm,
  noOnTimePresent,
  fiveOrderCourse,
  todayBacklog,
  getCount,
  getTaskProgressByLeadsId,
  doCheckLeads,
  getUserLessonId,
  getDemoFeedBackInfo,
  getDemoPreviewUrl,
  getRecommendGoods,
  sendUserGoods,
  getUserRecommendGoods,
  getUnAllocate,
  allocate,
} from '../../services/ccTodayTask';

export default {
  namespace: 'ccTodayTask',

  state: {
    reallocateCycle: null, // 再分配周期 分钟
    autoReallocateSwitch: false, // 再分配配置开关, true: 走正常的流转倒计时逻辑; false: 流转时间直接显示--，不进行倒计时

    taskCount: {
      newLeadsCount: 0, // 新leads数
      todayPresentOrderCount: 0, // 今日出席促单数, 出席未完成任务数
      noAttendCount: 0, // 今日出席促单 -- 未出席时未完成任务数
      fiveNoOrderCount: 0, // 5日内未签单
      presentNoConfirmCount: 0, // 待出席确认数 ,次日出席未完成任务数
      presentNoConfirm7DayCount: 0, // 待出席确认数 - 7日内出席未完成任务数
      noOnTimePresentCount: 0, // 未按时出席
      fiveOrderCourseCount: 0, // 5日内约课未完成任务数,未接通时,未完成任务数
      fiveOrderCourse4ConnectCount: 0, // 5日内约课未完成任务数- 已接通未完成任务数
      todayBacklogCount: 0, // 今日待办数
      formPublicSeaNewLeadsCount: 0, // 来自公海的新leads数量
    },

    taskProgress: [], // 获CC对某个leads今日任务完成情况列表

    newLeads: [], // 新leads列表
    listFromSeaLeads: [], // 新leads - 公海 leads
    todayPresentOrder: [], // 今日出席促单列表
    fiveNoOrder: [], // 5日内未签单列表
    presentNoConfirm: [], // 待出席确认列表
    noOnTimePresent: [], // 未按时出席列表
    fiveOrderCourse: [], // 5日内未约课列表
    todayBacklog: [], // 今日待办列表

    less10MinNum: 0, // 有多少条 leads 将在 10 分钟内流转
    isReloadNewLeads: false, // 是否重新 reload NewLeads 的 table

    allLeadsList: [],
    unAllocateCount: 0,
  },

  effects: {
    *newLeads({ payload }: any, { call, put }: any): any {
      yield put({ type: 'getCount' }); // 每次重新请求列表都要调用一次统计数据接口

      const response = yield call(newLeads, payload);

      const { autoReallocateSwitch, reallocateCycle, taskList } =
        response.data || {};

      yield put({
        type: '_setCycle',
        payload: reallocateCycle,
      });

      yield put({
        type: '_setAutoReallocateSwitch',
        payload: autoReallocateSwitch,
      });

      yield put({
        type: '_setTableData',
        payload: {
          key: 'newLeads',
          list: taskList,
        },
      });

      // ProTable 的 request 属性，需要返回特定数据结构的 Promise 对象
      return {
        data: taskList,
        success: true,
      };
    },

    *listFromSeaLeads({ payload }: any, { call, put }: any): any {
      yield put({ type: 'getCount' }); // 每次重新请求列表都要调用一次统计数据接口

      const response = yield call(listFromSeaLeads, payload);

      const {
        records = [],
        current = 1,
        size = 10,
        total = 0,
      } = response.data || {};

      yield put({
        type: '_setTableData',
        payload: {
          key: 'listFromSeaLeads',
          list: records,
        },
      });

      // ProTable 的 request 属性，需要返回特定数据结构的 Promise 对象
      return {
        data: records,
        success: true,
        total,
        page: current,
        pageSize: size,
      };
    },

    *todayPresentOrder({ payload }: any, { call, put }: any): any {
      yield put({ type: 'getCount' }); // 每次重新请求列表都要调用一次统计数据接口

      const response = yield call(todayPresentOrder, payload);

      yield put({
        type: '_setTableData',
        payload: {
          key: 'todayPresentOrder',
          list: response.data,
        },
      });

      // ProTable 的 request 属性，需要返回特定数据结构的 Promise 对象
      return {
        data: response.data,
        success: true,
      };
    },

    *fiveNoOrder({ payload }: any, { call, put }: any): any {
      yield put({ type: 'getCount' }); // 每次重新请求列表都要调用一次统计数据接口

      const response = yield call(fiveNoOrder, payload);

      yield put({
        type: '_setTableData',
        payload: {
          key: 'fiveNoOrder',
          list: response.data,
        },
      });

      // ProTable 的 request 属性，需要返回特定数据结构的 Promise 对象
      return {
        data: response.data,
        success: true,
      };
    },

    *presentNoConfirm({ payload }: any, { call, put }: any): any {
      yield put({ type: 'getCount' }); // 每次重新请求列表都要调用一次统计数据接口

      const response = yield call(presentNoConfirm, payload);

      yield put({
        type: '_setTableData',
        payload: {
          key: 'presentNoConfirm',
          list: response.data,
        },
      });

      // ProTable 的 request 属性，需要返回特定数据结构的 Promise 对象
      return {
        data: response.data,
        success: true,
      };
    },

    *noOnTimePresent({ payload }: any, { call, put }: any): any {
      yield put({ type: 'getCount' }); // 每次重新请求列表都要调用一次统计数据接口

      const response = yield call(noOnTimePresent, payload);

      yield put({
        type: '_setTableData',
        payload: {
          key: 'noOnTimePresent',
          list: response.data,
        },
      });

      // ProTable 的 request 属性，需要返回特定数据结构的 Promise 对象
      return {
        data: response.data,
        success: true,
      };
    },

    *fiveOrderCourse({ payload }: any, { call, put }: any): any {
      yield put({ type: 'getCount' }); // 每次重新请求列表都要调用一次统计数据接口

      const response = yield call(fiveOrderCourse, payload);

      yield put({
        type: '_setTableData',
        payload: {
          key: 'fiveOrderCourse',
          list: response.data,
        },
      });

      // ProTable 的 request 属性，需要返回特定数据结构的 Promise 对象
      return {
        data: response.data,
        success: true,
      };
    },

    *todayBacklog({ payload }: any, { call, put }: any): any {
      yield put({ type: 'getCount' }); // 每次重新请求列表都要调用一次统计数据接口

      const response = yield call(todayBacklog, payload);

      yield put({
        type: '_setTableData',
        payload: {
          key: 'todayBacklog',
          list: response.data,
        },
      });

      // ProTable 的 request 属性，需要返回特定数据结构的 Promise 对象
      return {
        data: response.data,
        success: true,
      };
    },

    *getUnAllocate({ payload }: any, { call, put }: any): any {
      yield put({ type: 'getCount' }); // 每次重新请求列表都要调用一次统计数据接口

      const response = yield call(getUnAllocate, payload);
      const { records = [], total = 0 } = response.data;

      yield put({
        type: '_setTableData',
        payload: {
          key: 'undistributed',
          list: records,
        },
      });

      yield put({
        type: '_getUnAllocateCount',
        payload: total,
      });

      return {
        data: records,
        total: total,
        success: true,
      };
    },

    *allocate({ payload }: any, { call, put }: any): any {
      const response = yield call(allocate, payload);

      return response;
    },

    *getCount({ payload }: any, { call, put }: any): any {
      const response = yield call(getCount, { businessType: 1 });

      yield put({
        type: '_getCount',
        payload: response,
      });

      return response;
    },

    *getTaskProgressByLeadsId({ payload }: any, { call, put }: any): any {
      const response = yield call(getTaskProgressByLeadsId, payload);

      yield put({
        type: '_getTaskProgressByLeadsId',
        payload: response,
      });

      return response;
    },

    *doCheckLeads({ payload }: any, { call, put }: any): any {
      const response = yield call(doCheckLeads, payload);

      return response;
    },

    *getUserLessonId({ payload }: any, { call, put }: any): any {
      const response = yield call(getUserLessonId, payload);

      return response;
    },

    *getDemoFeedBackInfo({ payload }: any, { call, put }: any): any {
      const response = yield call(getDemoFeedBackInfo, payload);

      return response;
    },

    *getDemoPreviewUrl({ payload }: any, { call, put }: any): any {
      const response = yield call(getDemoPreviewUrl, payload);

      return response;
    },

    *getRecommendGoods({ payload }: any, { call, put }: any): any {
      const response = yield call(getRecommendGoods, payload);

      return response;
    },

    *sendUserGoods({ payload }: any, { call, put }: any): any {
      const response = yield call(sendUserGoods, payload);

      return response;
    },

    *getUserRecommendGoods({ payload }: any, { call, put }: any): any {
      const response = yield call(getUserRecommendGoods, payload);

      return response;
    },
  },

  reducers: {
    _getCount(state: any, { payload }: any) {
      const { data } = payload;

      return {
        ...state,
        taskCount: data,
      };
    },

    _getUnAllocateCount(state: any, { payload }: any) {
      return {
        ...state,
        unAllocateCount: payload,
      };
    },

    _getTaskProgressByLeadsId(state: any, { payload }: any) {
      const { data } = payload;

      return {
        ...state,
        taskProgress: data,
      };
    },

    _setTableData(state: any, { payload }: any) {
      const { key, list } = payload;

      return {
        ...state,
        allLeadsList: list,
        [key]: list,
      };
    },

    _setCycle(state: any, { payload }: any) {
      return {
        ...state,
        reallocateCycle: payload >> 0,
      };
    },

    _setAutoReallocateSwitch(state: any, { payload }: any) {
      return {
        ...state,
        autoReallocateSwitch: payload,
      };
    },

    _setLess10MinNum(state: any, { payload }: any) {
      return {
        ...state,
        less10MinNum: payload,
      };
    },

    _setIsReloadNewLeads(state: any, { payload }: any) {
      return {
        ...state,
        isReloadNewLeads: payload,
      };
    },

    _allLeadsList(state: any, { payload }: any) {
      return {
        ...state,
        allLeadsList: payload,
      };
    },
  },
};
