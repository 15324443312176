/*
 * @Author: liusuyun
 * @Date: 2022-02-28 17:33:37
 * @LastEditors: liusuyun
 * @LastEditTime: 2022-03-28 17:21:28
 * @Description:
 */
import request from '@/utils/request';
/**
 * 获取当前账号所属组织下的组织架构
 */
export async function listChildrenTreeByAccount(params: any) {
  return request('/crm-account/dept/listChildrenTreeByAccount', {
    method: 'GET',
    data: params,
  });
}

/**
 * 手动分配：cc的leads分配列表查询
 */
export async function listAllocateOfCC(params: any) {
  return request('/crm-market/cc/listAllocateOfCC', {
    method: 'GET',
    data: params,
  });
}
