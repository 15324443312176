/*
 * @Author: your name
 * @Date: 2021-09-22 23:19:38
 * @LastEditTime: 2022-03-02 14:28:09
 * @LastEditors: liusuyun
 * @Description: In User Settings Edit
 */
import request from '@/utils/request';
/**
 * 线下补差回调
 */
 export async function offlinePayCallback(params: any) {
  return request('/crm-teaching/relegation/offlinePayCallback', {
    method: 'GET',
    data: params,
  });
}
/**
 * 获取课程等级对应的商品价格
 */
 export async function getGoodPriceByCourseLayer(params: any) {
  return request('/crm-teaching/relegation/getGoodPriceByCourseLayer', {
    method: 'GET',
    data: params,
  });
}
/**
 * 获取课程等级
 */
 export async function getCourseLayerList(params: any) {
  return request('/crm-teaching/relegation/getCourseLayerList', {
    method: 'GET',
    data: params,
  });
}

/**
 * 获取申请记录id对应的支付流水记录
 */
 export async function getPayTradeByRecordId(params: any) {

  return request('/crm-teaching/relegation/getPayTradeByRecordId', {
    method: 'GET',
    data: params,
  });
}
/**
 * 获取申请记录id对应的课消修改记录
 */
export async function getConsumePeriodLog(params: any) {
  return request('/crm-teaching/relegation/getConsumePeriodLog', {
    method: 'GET',
    data: params,
  });
}

/**
 * 获取申请记录id对应的流转记录
 */
export async function getCirculationList(params: any) {
  return request('/crm-teaching/relegation/getCirculationList', {
    method: 'GET',
    data: params,
  });
}
/**
 * 保存/提交申请
 */
export async function relegationApply(params: any) {
  return request('/crm-teaching/relegation/apply', {
    method: 'GET',
    data: params,
    head: 'application/x-www-form-urlencoded',
  });
}
/**
 * 升降级提交-课消提示 提交申请的时候先调这个接口，判断已消课次和已填课次是否相同
 * 可以选择强制升降级
 */
export async function getConsumePeriod(params: any) {
  return request('/crm-teaching/relegation/getConsumePeriod', {
    method: 'GET',
    data: params,
  });
}
/**
 * 撤销申请 传申请记录id
 */
export async function relegationCancel(params: any) {
  return request('/crm-teaching/relegation/cancel', {
    method: 'GET',
    data: params,
  });
}
/**
 * 获取订单对应的课消信息
 */
export async function getConsumePeriodByOrderNo(params: any) {
  return request('/crm-teaching/relegation/getConsumePeriodByOrderNo', {
    method: 'GET',
    data: params,
  });
}
/**
 * 获取学员升降级订单信息
 */
export async function getRelegationOrderByUserId(params: any) {

  return request('/crm-teaching/relegation/getRelegationOrderByUserId', {
    method: 'GET',
    data: params,
  });
}
/**
 * 校区全部学员列表
 */
export async function listSchoolStudent(params: any) {
  return request('/crm-teaching/student/listSchoolStudent', {
    method: 'GET',
    data: params
  });
}

/**
 * 校区学员数据统计接口
 */
export async function schoolStudentData(params: any) {
  return request('/crm-teaching/student/schoolStudentData', {
    method: 'GET',
    data: params
  });
}

/**
 * 获取学员详情
 */
export async function details(params: any) {
  return request('/crm-teaching/student/details', {
    method: 'GET',
    data: params
  });
}

/**
 * 修改学员详情
 */
export async function updateStudentDetails(params: any) {
  return request('/crm-teaching/student/updateStudentDetails', {
    method: 'POST',
    data: params
  });
}

/**
 * 获取学员课程列表接口
 */
export async function getCourseBySid(params: any) {
  return request('/crm-teaching/student/getCourseBySid', {
    method: 'GET',
    data: params
  });
}

/**
 * 学员-获取学员教务操作日志
 */
export async function getEduOperation(params: any) {
  return request('/crm-teaching/student/getEduOperation', {
    method: 'GET',
    data: params
  });
}

/**
 * 添加学员回访记录
 */
export async function addRevisitRecord(params: any) {
  return request('/crm-teaching/student/addRevisitRecord', {
    method: 'POST',
    data: params
  });
}

/**
 * CC工作台-查询我的学员列表-待开班
 */
export async function listWaitOpenClass(params: any) {
  return request('/crm-teaching/student/listWaitOpenClass', {
    method: 'GET',
    data: params
  });
}

/**
 * CC列表
 */
export async function getAllCcList() {
  return request('/crm-account/account/getAllCcList');
}

/**
 * 查询当前账号所属组织下的所有CC（会根据当前账号的角色进行筛选）
 */
 export async function listAllCcByDept(params: any) {
  return request('/crm-account/account/listAllCcByDept',{
    method: 'GET',
    data: params,
  });
}

/**
 * CC工作台-查询我的学员列表-续费
 */
export async function listByCourseProgress(params: any) {
  return request('/crm-teaching/student/listByCourseProgress', {
    method: 'GET',
    data: params
  });
}

/**
 * CC工作台-查询我的学员列表-打卡
 */
export async function listByClock(params: any) {
  return request('/crm-teaching/student/listByClock', {
    method: 'GET',
    data: params
  });
}
