/*
 * @Author: liusuyun
 * @Date: 2022-03-14 12:21:34
 * @LastEditors: liusuyun
 * @LastEditTime: 2022-03-14 19:33:40
 * @Description:系统消息
 */
import request from '@/utils/request';

/**
 * 获取消息接口
 */
export async function getMessagePage(params: any) {
  return request('/crm-account/system/message/getMessagePage', {
    method: 'GET',
    data: params,
  });
}

/**
 * 查看消息接口
 */
export async function lookUpMessage(params: any) {
  return request('/crm-account/system/message/lookUpMessage', {
    method: 'POST',
    data: params,
  });
}

/**
 * 清空消息接口
 */
export async function cleanUpMessage(params: any) {
  return request('/crm-account/system/message/cleanUpMessage', {
    method: 'POST',
    data: params,
  });
}
